import {
  RESET_SALES,
  SET_SALES,
  SET_SALES_NAMES,
  SET_SALES_SORTING_COLUMN,
  SET_SALES_SORTING_ASCENDING,
  SET_SELECTED_SALE,
  SET_NEW_COLOR,
  SET_NEW_PRODUCT,
  SET_NEW_SIZE,
  SET_NEW_STORE,
  SET_NEW_QUANTITY,
  SET_NEW_PRICE,
  SET_NEW_NOTES,
} from './types'

const INIT_STATE = {
  sales: [],
  salesNames: [],
  salesSortingColumn: undefined,
  salesSortingAscending: true,
  selectedSale: undefined,
  newColor: undefined,
  newProduct: undefined,
  newSize: undefined,
  newStore: undefined,
  newQuantity: undefined,
  newPrice: undefined,
  newNotes: undefined,
}

export const salesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_SALES:
      return INIT_STATE
    case SET_SALES:
      return { ...state, sales: action.payload }
    case SET_SALES_NAMES:
      return { ...state, salesNames: action.payload }
    case SET_SALES_SORTING_COLUMN:
      return { ...state, salesSortingColumn: action.payload }
    case SET_SALES_SORTING_ASCENDING:
      return { ...state, salesSortingAscending: action.payload }
    case SET_SELECTED_SALE:
      return { ...state, selectedSale: action.payload }
    case SET_NEW_COLOR:
      return { ...state, newColor: action.payload }
    case SET_NEW_PRODUCT:
      return { ...state, newProduct: action.payload }
    case SET_NEW_SIZE:
      return { ...state, newSize: action.payload }
    case SET_NEW_STORE:
      return { ...state, newStore: action.payload }
    case SET_NEW_QUANTITY:
      return { ...state, newQuantity: action.payload }
    case SET_NEW_PRICE:
      return { ...state, newPrice: action.payload }
    case SET_NEW_NOTES:
      return { ...state, newNotes: action.payload }
    default:
      return state
  }
}
