export const utilsFunctions = () => {
  const fromDateToString = date => {
    if (!(date instanceof Date)) return null

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).padStart(4, '0')

    return `${day}/${month}/${year}`
  }

  const fromTimestampToDateString = timestamp => {
    if (!(typeof timestamp === 'number')) return null

    const date = new Date(timestamp)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).padStart(4, '0')

    return `${day}/${month}/${year}`
  }

  const rangeFun = i => (i ? rangeFun(i - 1).concat(i) : [])

  return { fromDateToString, fromTimestampToDateString, rangeFun }
}
