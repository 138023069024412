import {
  RESET_INVENTORY,
  SET_INVENTORY,
  SET_INVENTORY_NAMES,
  SET_INVENTORY_SORTING_COLUMN,
  SET_INVENTORY_SORTING_ASCENDING,
  SET_SELECTED_INVENTORY,
  SET_NEW_COLOR,
  SET_NEW_PRODUCT,
  SET_NEW_SIZE,
  SET_NEW_STORE,
  SET_NEW_QUANTITY,
  SET_NEW_PRICE,
} from './types'

const INIT_STATE = {
  inventory: [],
  inventoryNames: [],
  inventorySortingColumn: undefined,
  inventorySortingAscending: true,
  selectedInventory: undefined,
  newColor: undefined,
  newProduct: undefined,
  newSize: undefined,
  newStore: undefined,
  newQuantity: undefined,
  newPrice: undefined,
}

export const inventoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_INVENTORY:
      return INIT_STATE
    case SET_INVENTORY:
      return { ...state, inventory: action.payload }
    case SET_INVENTORY_NAMES:
      return { ...state, inventoryNames: action.payload }
    case SET_INVENTORY_SORTING_COLUMN:
      return { ...state, inventorySortingColumn: action.payload }
    case SET_INVENTORY_SORTING_ASCENDING:
      return { ...state, inventorySortingAscending: action.payload }
    case SET_SELECTED_INVENTORY:
      return { ...state, selectedInventory: action.payload }
    case SET_NEW_COLOR:
      return { ...state, newColor: action.payload }
    case SET_NEW_PRODUCT:
      return { ...state, newProduct: action.payload }
    case SET_NEW_SIZE:
      return { ...state, newSize: action.payload }
    case SET_NEW_STORE:
      return { ...state, newStore: action.payload }
    case SET_NEW_QUANTITY:
      return { ...state, newQuantity: action.payload }
    case SET_NEW_PRICE:
      return { ...state, newPrice: action.payload }
    default:
      return state
  }
}
