import React from 'react'
import { Box, Grid } from '@mui/material'

import { colours, logos, links } from '../settings/settings'

const Home = () => {
  document.title = 'GED - Home'

  const mail = <a href='mailto:info@gedhomemade.com'>info@gedhomemade.com</a>

  const imageWidth = '80%'
  const logoWidth = Math.max(window.innerWidth * 0.08, 75)

  const GridContainerProps = {
    container: true,
    item: true,
    xs: 12,
    alignItems: 'center',
    justifyContent: 'center',
  }

  const GridText = ({
    text,
    type,
    fontSize = 26,
    fontWeight = 'normal',
    textAlign = 'center',
    xs = 12,
    md = 12,
  }) => {
    if (type === 'title') {
      fontSize = 40
      fontWeight = 'bold'
    }
    if (type === 'subtitle') {
      fontSize = 33
      fontWeight = 'bold'
    }

    return (
      <Grid item xs={xs} md={md}>
        <center>
          <p
            style={{
              fontWeight,
              fontSize,
              textAlign,
              color: colours.homeText,
              fontFamily: 'Open Sans',
            }}
          >
            {text}
          </p>
        </center>
      </Grid>
    )
  }

  const GridImage = ({ src, alt, xs = 12, md = 6 }) => (
    <Grid item xs={xs} md={md}>
      <center>
        <img src={src} alt={alt} width={imageWidth} />
      </center>
    </Grid>
  )

  const GridLogo = ({ src, alt, href, xs = 5, md = 2 }) => (
    <Grid item xs={xs} md={md}>
      <center>
        {href ? (
          <a href={href} target='_blank' rel='noopener noreferrer'>
            <img src={src} alt={alt} width={logoWidth} />
          </a>
        ) : (
          <img src={src} alt={alt} width={logoWidth} />
        )}
      </center>
    </Grid>
  )

  const GridSpace = ({ xs = 12 }) => (
    <Grid item xs={xs}>
      <Box sx={{ height: '50px' }} />
    </Grid>
  )

  const GridProduct = ({ src, alt, xs = 6, md = 3 }) => (
    <Grid item xs={xs} md={md}>
      <center>
        <GridImage md={12} alt={alt} src={src} />
        <GridText md={12} type='subtitle' text={alt} />
      </center>
    </Grid>
  )

  const GridInfo = ({ title, text, xs = 12 }) => (
    <Grid {...GridContainerProps} xs={xs} paddingX={1}>
      <GridText xs={4} md={3} fontSize={20} text={title} />
      <GridText
        xs={8}
        md={9}
        fontSize={30}
        type='subtitle'
        text={text}
        textAlign='left'
      />
    </Grid>
  )

  return (
    <Grid container>
      <Grid
        {...GridContainerProps}
        spacing={2}
        style={{ backgroundColor: colours.white }}
      >
        <GridText type='title' text='HANDMADE CROCHET, CRAFTS, AND MORE!' />
        <Grid item xs={1} />
        <GridLogo
          alt='Instagram'
          src={logos.instagram}
          href={links.instagram}
        />
        <GridLogo alt='Facebook' src={logos.facebook} href={links.facebook} />
        <Grid item xs={1} />
        <GridImage alt='Products' src='/Veterans.jpeg' />
        <GridText md={6} type='subtitle' text='CHECK OUR ETSY SHOPS' />
        <Grid item xs={1} />
        <GridLogo alt='Etsy Shop US' src={logos.etsyUS} href={links.etsyUS} />
        <GridLogo alt='Etsy Shop EU' src={logos.etsyEU} href={links.etsyEU} />
        <Grid item xs={1} />
        <GridSpace />
      </Grid>
      <Grid
        {...GridContainerProps}
        spacing={2}
        style={{ backgroundColor: colours.homeBackground }}
      >
        <GridText type='title' text='OUR BEST SELLERS' />
        <Grid {...GridContainerProps} alignItems='flex-start'>
          <GridProduct src='/TacoKeychain.png' alt='Taco Keychain' />
          <GridProduct src='/BumbleBee.png' alt='Bumble Bee' />
          <GridProduct src='/Jellyfish.png' alt='Jackie Jellyfish' />
          <GridProduct src='/VolleyKeychain.png' alt='Volleyball Keychain' />
        </Grid>
        <GridText
          text={<>For the complete inventory list, write at {mail}.</>}
        />
        <GridSpace />
      </Grid>
      <Grid
        {...GridContainerProps}
        spacing={2}
        style={{ backgroundColor: colours.white }}
      >
        <GridText type='title' text='PAST CRAFT FAIRS' />
        <Grid {...GridContainerProps} md={12}>
          <GridImage alt='NextEvent1' src='/Northport.jpeg' />
          <Grid {...GridContainerProps} md={6}>
            <GridInfo title='WHAT' text='Spring Craft Fair' />
            <GridInfo title='WHEN' text='Saturday, May 11' />
            <GridInfo title='WHERE' text='Northport, Long Island NY' />
          </Grid>
        </Grid>
        <Grid {...GridContainerProps} md={12}>
          <GridImage alt='NextEvent2' src='/Huntington.jpeg' />
          <Grid {...GridContainerProps} md={6}>
            <GridInfo title='WHAT' text='Waterfront Craft Festival' />
            <GridInfo title='WHEN' text='Sunday, May 19' />
            <GridInfo title='WHERE' text='Huntington, Long Island NY' />
          </Grid>
        </Grid>
        <GridSpace />
      </Grid>
      <Grid
        {...GridContainerProps}
        spacing={2}
        style={{ backgroundColor: colours.homeBackground }}
      >
        <GridText type='title' text='WHO WE ARE' />
        <GridImage xs={10} md={4} alt='Giulia' src='/Giulia.png' />
        <Grid {...GridContainerProps} xs={10} md={4}>
          <GridText
            textAlign='justify'
            text='Giulia and Erica, two friends addicted to homemade crafts. We
              started at a young age making all kinds of handmade projects.
              Since 2020, crochet has stolen our hearts.'
          />
          <GridText
            textAlign='justify'
            text='We have a lot in common. Among many shared interests, we are both
              world travelers who enjoy discovering new cities, customs, and
              cuisines. Our dream is to have our crochet plush toys travel all
              around the globe to reach our supporters!'
          />
        </Grid>
        <GridImage xs={10} md={4} alt='Erica' src='/Erica.png' />
        <GridSpace />
      </Grid>
    </Grid>
  )
}

export default Home
