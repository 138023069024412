import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField } from '@mui/material'

import { colours } from '../settings/settings'

const AutoComplete = ({
  id,
  value,
  blurOnSelect = true,
  clearOnBlur = true,
  clearOnEscape = true,
  disabled = false,
  disableCloseOnSelect = true,
  fullWidth = true,
  multiple = false,
  openable = true,
  optional = false,
  readOnly = false,
  showPopupIcon = true,
  size = 'medium',
  type = 'text',
  label = '',
  variant = 'filled',
  options = [],
  groupBy = () => {},
  getOptionLabel = option => option,
  getOptionDisabled = () => false,
  isOptionEqualToValue = (option, value) => {
    if (!!option?.id && !!value?.id) return option.id === value.id
    return option === value
  },
  onChange = () => {},
  onInputChange = () => {},
  noOptionsText = 'No options',
  clearText = 'Clear',
  closeText = 'Close',
  openText = 'Open',
  mode,
  helperText = '',
  errorText = 'Choose a value!',
  errorFunc = value => mode === 'update' && !value && !optional,
}) => {
  // state to manage the input validation status
  const [isError, setIsError] = useState(false)

  const isReadOnly = readOnly || mode === 'view'

  const emptyValue = multiple ? [] : null

  useEffect(() => {
    if (errorFunc) setIsError(errorFunc(value))
  }, [errorFunc, value])

  return (
    <Autocomplete
      id={id}
      blurOnSelect={!!blurOnSelect}
      clearOnBlur={!!clearOnBlur}
      clearOnEscape={!!clearOnEscape}
      disabled={!!disabled}
      disableCloseOnSelect={!!disableCloseOnSelect}
      forcePopupIcon={!!showPopupIcon ? 'auto' : false}
      fullWidth={!!fullWidth}
      multiple={!!multiple}
      open={!!openable ? undefined : false}
      readOnly={!!isReadOnly}
      size={size}
      renderInput={params => (
        <TextField
          {...params}
          type={type}
          label={label}
          variant={variant}
          error={!!isError}
          helperText={(!!isError && errorText) || helperText}
          InputProps={{
            ...params.InputProps,
            style: {
              backgroundColor:
                (!!disabled && 'transparent') ||
                (!!isError && colours.redError),
            },
          }}
        />
      )}
      value={value || emptyValue}
      options={options}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(_, value) => onChange(value)}
      onInputChange={onInputChange}
      onBlur={event => errorFunc && setIsError(errorFunc(event.target.value))}
      noOptionsText={noOptionsText}
      clearText={clearText}
      closeText={closeText}
      openText={openText}
    />
  )
}

export default AutoComplete
