import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home'
import ListAltIcon from '@mui/icons-material/ListAlt'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { colours } from '../settings/settings'
import { SET_IS_OPEN_DRAWER } from '../container/home/types'

const drawerWidth = 200

const openedMixin = theme => ({
  width: drawerWidth,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = theme => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const MyDivider = styled(Divider)(() => ({ background: colours.drawerText }))

const MyDrawer = ({ auth, isMobile, isOpenDrawer }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const location = useLocation()

  const menuItems0 = [{ label: 'Home', link: '/', icon: <HomeIcon /> }]

  let menuItems1 = []
  if (auth)
    menuItems1 = [
      ...menuItems1,
      { label: 'Registry', link: '/registry', icon: <ListAltIcon /> },
      { label: 'Inventory', link: '/inventory', icon: <StorefrontIcon /> },
      { label: 'Sales', link: '/sales', icon: <PointOfSaleIcon /> },
    ]

  const MyListItem = ({ item }) => (
    <ListItem
      disablePadding
      sx={{
        display: 'block',
        backgroundColor:
          item.link?.split('/')?.[1] === location.pathname?.split('/')?.[1] &&
          colours.blueLight,
        ':hover': { backgroundColor: colours.blue },
      }}
      onClick={() => {
        if (!!item?.func) item.func()
        if (!!item?.link) history.push(item.link)
        if (isMobile) dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })
      }}
    >
      <ListItemButton>
        <ListItemIcon sx={{ color: colours.drawerText }}>
          {item?.icon || <ChevronRightIcon />}
        </ListItemIcon>
        <ListItemText
          primary={item?.label}
          primaryTypographyProps={{
            color: colours.drawerText,
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    </ListItem>
  )

  const DrawerContent = (
    <>
      <Toolbar />
      {menuItems0?.length > 0 && (
        <List>
          {menuItems0.map(i => (
            <MyListItem key={i.label} item={i} />
          ))}
        </List>
      )}
      {menuItems1?.length > 0 && (
        <>
          <MyDivider />
          <List>
            {menuItems1.map(i => (
              <MyListItem key={i.label} item={i} />
            ))}
          </List>
        </>
      )}
      <MyDivider />
    </>
  )

  return isMobile ? (
    <SwipeableDrawer
      open={isOpenDrawer}
      onOpen={() => dispatch({ type: SET_IS_OPEN_DRAWER, payload: true })}
      onClose={() => dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })}
      PaperProps={{ sx: { backgroundColor: colours.blueDark } }}
    >
      {DrawerContent}
    </SwipeableDrawer>
  ) : (
    <StyledDrawer
      variant='permanent'
      open={isOpenDrawer}
      PaperProps={{ sx: { backgroundColor: colours.blueDark } }}
      onMouseEnter={() => dispatch({ type: SET_IS_OPEN_DRAWER, payload: true })}
      onMouseLeave={() =>
        dispatch({ type: SET_IS_OPEN_DRAWER, payload: false })
      }
    >
      {DrawerContent}
    </StyledDrawer>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
  isMobile: state.home.isMobile,
  isOpenDrawer: state.home.isOpenDrawer,
})

const ConnectedMyDrawer = connect(mapStateToProps)(MyDrawer)

export default ConnectedMyDrawer
