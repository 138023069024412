export const RESET_SALES = '@@sales/RESET_SALES'

export const SET_SALES = '@@sales/SET_SALES'
export const SET_SALES_NAMES = '@@sales/SET_SALES_NAMES'
export const SET_SALES_SORTING_COLUMN = '@@sales/SET_SALES_SORTING_COLUMN'
export const SET_SALES_SORTING_ASCENDING = '@@sales/SET_SALES_SORTING_ASCENDING'

export const SET_SELECTED_SALE = '@@sales/SET_SELECTED_SALE'
export const SET_NEW_COLOR = '@@inventory/SET_NEW_COLOR'
export const SET_NEW_PRODUCT = '@@inventory/SET_NEW_PRODUCT'
export const SET_NEW_SIZE = '@@inventory/SET_NEW_SIZE'
export const SET_NEW_STORE = '@@inventory/SET_NEW_STORE'
export const SET_NEW_QUANTITY = '@@sales/SET_NEW_QUANTITY'
export const SET_NEW_PRICE = '@@sales/SET_NEW_PRICE'
export const SET_NEW_NOTES = '@@sales/SET_NEW_NOTES'
