import {
  RESET_HOME,
  SET_AUTH,
  SET_CODICE_UTENTE,
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_IS_MOBILE,
  SET_IS_OPEN_DRAWER,
  SET_STATS,
  SET_COLORS,
  SET_COLORS_SORTING_COLUMN,
  SET_COLORS_SORTING_ASCENDING,
  SET_PRODUCTS,
  SET_PRODUCTS_SORTING_COLUMN,
  SET_PRODUCTS_SORTING_ASCENDING,
  SET_SIZES,
  SET_SIZES_SORTING_COLUMN,
  SET_SIZES_SORTING_ASCENDING,
  SET_STORES,
  SET_STORES_SORTING_COLUMN,
  SET_STORES_SORTING_ASCENDING,
  SET_BARCODES,
  SET_SELECTED_COLOR,
  SET_SELECTED_PRODUCT,
  SET_SELECTED_SIZE,
  SET_SELECTED_STORE,
  SET_SELECTED_DATES,
} from './types'

const INIT_STATE = {
  auth: undefined,
  codiceUtente: undefined,
  error: undefined,
  dummyUpdate: true,
  isMobile:
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
  isOpenDrawer: false,
  stats: undefined,
  colors: undefined,
  colorsSortingColumn: undefined,
  colorsSortingAscending: true,
  products: undefined,
  productsSortingColumn: undefined,
  productsSortingAscending: true,
  sizes: undefined,
  sizesSortingColumn: undefined,
  sizesSortingAscending: true,
  stores: undefined,
  storesSortingColumn: undefined,
  storesSortingAscending: true,
  barcodes: undefined,
  selectedColor: undefined,
  selectedProduct: undefined,
  selectedSize: undefined,
  selectedStore: undefined,
  selectedDates: undefined,
}

export const homeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_HOME:
      return INIT_STATE
    case SET_AUTH:
      return { ...state, auth: action.payload }
    case SET_CODICE_UTENTE:
      return { ...state, codiceUtente: action.payload }
    case SET_ERROR:
      return { ...state, error: action.payload }
    case SET_DUMMY_UPDATE:
      return { ...state, dummyUpdate: action.payload }
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload }
    case SET_IS_OPEN_DRAWER:
      return { ...state, isOpenDrawer: action.payload }
    case SET_STATS:
      return { ...state, stats: action.payload }
    case SET_COLORS:
      return { ...state, colors: action.payload }
    case SET_COLORS_SORTING_COLUMN:
      return { ...state, colorsSortingColumn: action.payload }
    case SET_COLORS_SORTING_ASCENDING:
      return { ...state, colorsSortingAscending: action.payload }
    case SET_PRODUCTS:
      return { ...state, products: action.payload }
    case SET_PRODUCTS_SORTING_COLUMN:
      return { ...state, productsSortingColumn: action.payload }
    case SET_PRODUCTS_SORTING_ASCENDING:
      return { ...state, productsSortingAscending: action.payload }
    case SET_SIZES:
      return { ...state, sizes: action.payload }
    case SET_SIZES_SORTING_COLUMN:
      return { ...state, sizesSortingColumn: action.payload }
    case SET_SIZES_SORTING_ASCENDING:
      return { ...state, sizesSortingAscending: action.payload }
    case SET_STORES:
      return { ...state, stores: action.payload }
    case SET_STORES_SORTING_COLUMN:
      return { ...state, storesSortingColumn: action.payload }
    case SET_STORES_SORTING_ASCENDING:
      return { ...state, storesSortingAscending: action.payload }
    case SET_BARCODES:
      return { ...state, barcodes: action.payload }
    case SET_SELECTED_COLOR:
      return { ...state, selectedColor: action.payload }
    case SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload }
    case SET_SELECTED_SIZE:
      return { ...state, selectedSize: action.payload }
    case SET_SELECTED_STORE:
      return { ...state, selectedStore: action.payload }
    case SET_SELECTED_DATES:
      return { ...state, selectedDates: action.payload }
    default:
      return state
  }
}
