import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import {
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_STORES,
  SET_STORES_SORTING_COLUMN,
  SET_STORES_SORTING_ASCENDING,
} from '../container/home/types'

const columns = [
  { label: 'Name', sortable: true, sortingField: 'name' },
  { label: 'Currency', sortable: false },
  { label: 'Notes', sortable: true, sortingField: 'notes' },
  { label: 'Options', sortable: false },
]

const DialogStores = ({
  codiceUtente,
  dummyUpdate,
  stores,
  storesSortingColumn,
  storesSortingAscending,
}) => {
  const dispatch = useDispatch()

  // states to manage new values
  const [isAdding, setIsAdding] = useState()
  const [idUpdating, setIdUpdating] = useState()
  const [newName, setNewName] = useState()
  const [newCurrency, setNewCurrency] = useState()
  const [newNotes, setNewNotes] = useState()

  // reset state
  const resetState = () => {
    setIsAdding()
    setIdUpdating()
    setNewName()
    setNewCurrency()
    setNewNotes()
  }

  // add
  const addStore = async () => {
    try {
      await sendRequest(
        `stores/add`,
        'POST',
        JSON.stringify({
          name: newName,
          currency: newCurrency,
          notes: newNotes,
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      dispatch({ type: SET_ERROR, payload: 'Store added correctly' })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // update
  const updateStore = async id => {
    try {
      await sendRequest(
        `stores/${id}`,
        'PUT',
        JSON.stringify({
          name: newName,
          currency: newCurrency,
          notes: newNotes,
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete
  const deleteStore = async id => {
    try {
      await sendRequest(`stores/${id}`, 'DELETE', null, {
        Authorization: codiceUtente,
      })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      <Grid container item xs={12} spacing={3} alignItems='center'>
        {(isAdding && (
          <>
            <Grid item xs={12} md={3}>
              <center>
                <InputField
                  id='name'
                  value={newName}
                  onChange={setNewName}
                  autoFocus={true}
                  label={columns[0].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={12} md={2}>
              <center>
                <InputField
                  id='currency'
                  value={newCurrency}
                  onChange={setNewCurrency}
                  label={columns[1].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={12} md={5}>
              <center>
                <InputField
                  id='notes'
                  value={newNotes}
                  onChange={setNewNotes}
                  label={columns[2].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.blueDark }}
                  onClick={addStore}
                >
                  <SaveIcon />
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton style={{ color: colours.red }} onClick={resetState}>
                  <ClearIcon />
                </IconButton>
              </center>
            </Grid>
          </>
        )) || (
          <Grid item xs={12}>
            <center>
              <IconButton
                style={{ color: colours.black }}
                onClick={() => {
                  setIsAdding(true)
                  setIdUpdating()
                  setNewName()
                  setNewCurrency()
                  setNewNotes()
                }}
              >
                <AddIcon />
                &nbsp; New store
              </IconButton>
            </center>
          </Grid>
        )}
      </Grid>
      &nbsp;
      {(!stores && <h4>Stores not found</h4>) ||
        (stores.length === 0 && <h4>No stores found</h4>) || (
          <TableContainer component={Paper}>
            <Table size='small'>
              <SortableTableHead
                table={stores}
                setTable={i => dispatch({ type: SET_STORES, payload: i })}
                columns={columns}
                sortingColumn={storesSortingColumn}
                sortingAscending={storesSortingAscending}
                setSortingColumn={SET_STORES_SORTING_COLUMN}
                setSortingAscending={SET_STORES_SORTING_ASCENDING}
              />
              <TableBody>
                {stores.map(p => {
                  return (
                    <TableRow
                      key={p.id}
                      style={{ backgroundColor: colours.white }}
                    >
                      {(idUpdating === p.id && (
                        <>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='name'
                              value={newName}
                              onChange={setNewName}
                              autoFocus={true}
                              label={columns[0].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='currency'
                              value={newCurrency}
                              onChange={setNewCurrency}
                              label={columns[1].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='notes'
                              value={newNotes}
                              onChange={setNewNotes}
                              label={columns[2].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <Grid container>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => updateStore(p.id)}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={resetState}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )) || (
                        <>
                          <TableCell align='center'>{p.name}</TableCell>
                          <TableCell align='center'>{p.currency}</TableCell>
                          <TableCell align='center'>{p.notes}</TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => {
                                    setIsAdding()
                                    setIdUpdating(p.id)
                                    setNewName(p.name)
                                    setNewCurrency(p.currency)
                                    setNewNotes(p.notes)
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={() => deleteStore(p.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  stores: state.home.stores,
  storesSortingColumn: state.home.storesSortingColumn,
  storesSortingAscending: state.home.storesSortingAscending,
})

const ConnectedDialogStores = connect(mapStateToProps)(DialogStores)

export default ConnectedDialogStores
