import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import {
  SET_COLORS,
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_COLORS_SORTING_COLUMN,
  SET_COLORS_SORTING_ASCENDING,
} from '../container/home/types'

const columns = [
  { label: 'Name', sortable: true, sortingField: 'name' },
  { label: 'HEX Code', sortable: true, sortingField: 'hexCode' },
  { label: 'Notes', sortable: true, sortingField: 'notes' },
  { label: 'Options', sortable: false },
]

const DialogColors = ({
  codiceUtente,
  dummyUpdate,
  colors,
  colorsSortingColumn,
  colorsSortingAscending,
}) => {
  const dispatch = useDispatch()

  // states to manage new values
  const [isAdding, setIsAdding] = useState()
  const [idUpdating, setIdUpdating] = useState()
  const [newName, setNewName] = useState()
  const [newHexCode, setNewHexCode] = useState()
  const [newNotes, setNewNotes] = useState()

  // reset state
  const resetState = () => {
    setIsAdding()
    setIdUpdating()
    setNewName()
    setNewHexCode()
    setNewNotes()
  }

  // add
  const addColor = async () => {
    try {
      await sendRequest(
        `colors/add`,
        'POST',
        JSON.stringify({ name: newName, hexCode: newHexCode, notes: newNotes }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      dispatch({ type: SET_ERROR, payload: 'Color added correctly' })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // update
  const updateColor = async id => {
    try {
      await sendRequest(
        `colors/${id}`,
        'PUT',
        JSON.stringify({ name: newName, hexCode: newHexCode, notes: newNotes }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete
  const deleteColor = async id => {
    try {
      await sendRequest(`colors/${id}`, 'DELETE', null, {
        Authorization: codiceUtente,
      })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      <Grid container item xs={12} spacing={3} alignItems='center'>
        {(isAdding && (
          <>
            <Grid item xs={12} md={3}>
              <center>
                <InputField
                  id='name'
                  value={newName}
                  onChange={setNewName}
                  autoFocus={true}
                  label={columns[0].label}
                  helperText=' '
                />
              </center>
            </Grid>
            <Grid item xs={12} md={3}>
              <center>
                <InputField
                  id='hexCode'
                  value={newHexCode}
                  onChange={setNewHexCode}
                  label={columns[1].label}
                  helperText='If many separate with +'
                />
              </center>
            </Grid>
            <Grid item xs={12} md={4}>
              <center>
                <InputField
                  id='notes'
                  value={newNotes}
                  onChange={setNewNotes}
                  label={columns[2].label}
                  helperText=' '
                />
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.blueDark }}
                  onClick={addColor}
                >
                  <SaveIcon />
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton style={{ color: colours.red }} onClick={resetState}>
                  <ClearIcon />
                </IconButton>
              </center>
            </Grid>
          </>
        )) || (
          <Grid item xs={12}>
            <center>
              <IconButton
                style={{ color: colours.black }}
                onClick={() => {
                  setIsAdding(true)
                  setIdUpdating()
                  setNewName()
                  setNewHexCode()
                  setNewNotes()
                }}
              >
                <AddIcon />
                &nbsp; New color
              </IconButton>
            </center>
          </Grid>
        )}
      </Grid>
      &nbsp;
      {(!colors && <h4>Colors not found</h4>) ||
        (colors.length === 0 && <h4>No colors found</h4>) || (
          <TableContainer component={Paper}>
            <Table size='small'>
              <SortableTableHead
                table={colors}
                setTable={i => dispatch({ type: SET_COLORS, payload: i })}
                columns={columns}
                sortingColumn={colorsSortingColumn}
                sortingAscending={colorsSortingAscending}
                setSortingColumn={SET_COLORS_SORTING_COLUMN}
                setSortingAscending={SET_COLORS_SORTING_ASCENDING}
              />
              <TableBody>
                {colors.map(p => {
                  const hexCodeSplit = p.hexCode?.split('+')
                  return (
                    <TableRow
                      key={p.id}
                      style={{ backgroundColor: colours.white }}
                    >
                      {(idUpdating === p.id && (
                        <>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='name'
                              value={newName}
                              onChange={setNewName}
                              autoFocus={true}
                              label={columns[0].label}
                              helperText=' '
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='hexCode'
                              value={newHexCode}
                              onChange={setNewHexCode}
                              label={columns[1].label}
                              helperText='If many separate with +'
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='notes'
                              value={newNotes}
                              onChange={setNewNotes}
                              label={columns[2].label}
                              helperText=' '
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <Grid container>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => updateColor(p.id)}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={resetState}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )) || (
                        <>
                          <TableCell align='center'>{p.name}</TableCell>
                          <TableCell
                            align='center'
                            sx={{
                              background:
                                hexCodeSplit?.length > 1
                                  ? `linear-gradient(90deg, ${hexCodeSplit
                                      ?.map(
                                        (c, i) =>
                                          `#${c} ${
                                            (100 * i) / hexCodeSplit?.length
                                          }%, #${c} ${
                                            (100 * (i + 1)) /
                                            hexCodeSplit?.length
                                          }%`
                                      )
                                      ?.join(', ')})`
                                  : `#${p.hexCode}`,
                            }}
                          >
                            {p.hexCode}
                          </TableCell>
                          <TableCell align='center'>{p.notes}</TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => {
                                    setIsAdding()
                                    setIdUpdating(p.id)
                                    setNewName(p.name)
                                    setNewHexCode(p.hexCode)
                                    setNewNotes(p.notes)
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={() => deleteColor(p.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  colors: state.home.colors,
  colorsSortingColumn: state.home.colorsSortingColumn,
  colorsSortingAscending: state.home.colorsSortingAscending,
})

const ConnectedDialogColors = connect(mapStateToProps)(DialogColors)

export default ConnectedDialogColors
