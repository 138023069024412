export const errorFunctions = () => {
  const errorNumber = x => typeof x !== 'number'

  const errorIntero = x => errorNumber(x) || x !== Math.round(x)

  const errorNaturale = x => errorIntero(x) || x < 0

  const errorPositivo = x => errorNumber(x) || x < 0

  return { errorNumber, errorIntero, errorNaturale, errorPositivo }
}
