import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import Select from './Select'
import InputField from './InputField'
import AutoComplete from './AutoComplete'
import { errorFunctions } from '../hooks/error-functions'
import {
  SET_NEW_COLOR,
  SET_NEW_PRODUCT,
  SET_NEW_SIZE,
  SET_NEW_STORE,
  SET_NEW_QUANTITY,
  SET_NEW_PRICE,
} from '../container/inventory/types'

const DialogAddInventory = ({
  colors,
  products,
  sizes,
  stores,
  barcodes,
  newColor,
  newProduct,
  newSize,
  newStore,
  newQuantity,
  newPrice,
}) => {
  const dispatch = useDispatch()

  const mode = 'add'

  const [selectedBarcode, setSelectedBarcode] = useState()

  // error functions
  const { errorNaturale, errorPositivo } = errorFunctions()

  // reset newStoreInventory
  const resetNewStore = useCallback(async () => {
    localStorage.removeItem('newStoreInventory')
    dispatch({ type: SET_NEW_STORE })
  }, [dispatch])

  // set newStoreInventory
  const setNewStore = useCallback(
    async x => {
      if (x?.name) {
        localStorage.setItem('newStoreInventory', JSON.stringify(x?.name))
        dispatch({ type: SET_NEW_STORE, payload: x })
      } else {
        resetNewStore()
      }
    },
    [dispatch, resetNewStore]
  )

  // check newStoreInventory
  useEffect(() => {
    if (!newStore && stores?.length > 0)
      try {
        const storedValue = JSON.parse(
          localStorage.getItem('newStoreInventory')
        )
        if (stores?.map(s => s.name).includes(storedValue)) {
          dispatch({
            type: SET_NEW_STORE,
            payload: stores?.find(s => s.name === storedValue),
          })
        } else {
          resetNewStore()
        }
      } catch {
        resetNewStore()
      }

    if (
      newStore &&
      stores?.length > 0 &&
      !stores?.map(s => s.name)?.includes(newStore?.name)
    )
      resetNewStore()
  }, [dispatch, newStore, resetNewStore, stores])

  // set inputs
  useEffect(() => {
    dispatch({ type: SET_NEW_COLOR })
    dispatch({ type: SET_NEW_PRODUCT })
    dispatch({ type: SET_NEW_SIZE })
    dispatch({ type: SET_NEW_QUANTITY })
    dispatch({ type: SET_NEW_PRICE })
  }, [dispatch])

  // set barcode
  const setBarcode = b => {
    setSelectedBarcode(b)
    dispatch({
      type: SET_NEW_PRODUCT,
      payload: products?.find(p => p.id === b?.idProduct),
    })
    dispatch({
      type: SET_NEW_SIZE,
      payload: sizes?.find(s => s.id === b?.idSize),
    })
    dispatch({
      type: SET_NEW_COLOR,
      payload: colors?.find(c => c.id === b?.idColor),
    })
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_COLOR })
      dispatch({ type: SET_NEW_PRODUCT })
      dispatch({ type: SET_NEW_SIZE })
      dispatch({ type: SET_NEW_STORE })
      dispatch({ type: SET_NEW_QUANTITY })
      dispatch({ type: SET_NEW_PRICE })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12}>
          <Select
            id='store'
            label='Store*'
            value={newStore}
            options={stores}
            optionLabels={stores?.map(s => s?.name)}
            onChange={setNewStore}
            errorText='Select a store'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12}>
          <AutoComplete
            id='barcode'
            label='Barcode'
            value={selectedBarcode}
            options={barcodes}
            getOptionLabel={op => op?.barcode}
            onChange={setBarcode}
            errorText='Type a barcode'
            mode={mode}
            type='tel'
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            id='product'
            label='Product*'
            value={newProduct}
            options={products}
            optionLabels={products?.map(p => p?.name)}
            onChange={i => dispatch({ type: SET_NEW_PRODUCT, payload: i })}
            errorText='Select a product'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id='size'
            label='Size'
            value={newSize}
            options={sizes}
            optionLabels={sizes?.map(s => s?.name)}
            onChange={i => dispatch({ type: SET_NEW_SIZE, payload: i })}
            optional={true}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id='color'
            label='Color'
            value={newColor}
            options={colors}
            optionLabels={colors?.map(c => c?.name)}
            onChange={i => dispatch({ type: SET_NEW_COLOR, payload: i })}
            optional={true}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='quantity'
            label='Quantity*'
            value={newQuantity}
            onChange={i => dispatch({ type: SET_NEW_QUANTITY, payload: i })}
            errorFunc={errorNaturale}
            errorText='Cannot be negative'
            type='number'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='price'
            label='Price'
            value={newPrice}
            onChange={i => dispatch({ type: SET_NEW_PRICE, payload: i })}
            errorFunc={i => i && errorPositivo(i)}
            errorText='Cannot be negative'
            type='number'
            mode={mode}
          />
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  colors: state.home.colors,
  products: state.home.products,
  sizes: state.home.sizes,
  stores: state.home.stores,
  barcodes: state.home.barcodes,
  newColor: state.inventory.newColor,
  newProduct: state.inventory.newProduct,
  newSize: state.inventory.newSize,
  newStore: state.inventory.newStore,
  newQuantity: state.inventory.newQuantity,
  newPrice: state.inventory.newPrice,
})

const ConnectedDialogAddInventory = connect(mapStateToProps)(DialogAddInventory)

export default ConnectedDialogAddInventory
