import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FlightIcon from '@mui/icons-material/Flight'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { ColouredButton } from '../components/Buttons'
import {
  colours,
  titlesInventory as titles,
  phrasesInventory as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_INVENTORY_NAMES,
  SET_INVENTORY_SORTING_COLUMN,
  SET_INVENTORY_SORTING_ASCENDING,
  SET_SELECTED_INVENTORY,
} from '../container/inventory/types'

const Inventory = ({
  codiceUtente,
  dummyUpdate,
  colors,
  products,
  sizes,
  stores,
  barcodes,
  selectedColor,
  selectedProduct,
  selectedSize,
  selectedStore,
  inventory,
  inventoryNames,
  inventorySortingColumn,
  inventorySortingAscending,
  selectedInventory,
  newColor,
  newProduct,
  newSize,
  newStore,
  newQuantity,
  newPrice,
}) => {
  const dispatch = useDispatch()

  document.title = 'GED - Inventory'

  let columns = [
    { label: 'Barcode', sortable: true, sortingField: 'barcode' },
    { label: 'Product', sortable: true, sortingField: 'product' },
    { label: 'Size', sortable: true, sortingField: 'size' },
    { label: 'Color', sortable: true, sortingField: 'color' },
    { label: 'Store', sortable: true, sortingField: 'store' },
    { label: 'Quantity', sortable: true, sortingField: 'quantity' },
    { label: 'Price', sortable: true, sortingField: 'price' },
    { label: '', sortable: false },
  ]

  // states to manage the counters
  const [quantityByStore, setQuantityByStore] = useState()
  const [priceByStore, setPriceByStore] = useState()

  // state to manage the dialogs
  const [openAdd, setOpenAdd] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [deletingId, setDeletingId] = useState()

  const handleClickOpenAdd = () => {
    setOpenAdd(true)
  }

  const handleClickOpenUpdate = i => {
    dispatch({ type: SET_SELECTED_INVENTORY, payload: i })
    setOpenUpdate(true)
  }

  const handleClickOpenTransfer = i => {
    dispatch({ type: SET_SELECTED_INVENTORY, payload: i })
    setOpenTransfer(true)
  }

  const handleClickOpenDelete = id => {
    setDeletingId(id)
    setOpenDelete(true)
  }

  const handleCloseAdd = () => {
    setOpenAdd(false)
  }

  const handleCloseUpdate = () => {
    setOpenUpdate(false)
    dispatch({ type: SET_SELECTED_INVENTORY })
  }

  const handleCloseTransfer = () => {
    setOpenTransfer(false)
    dispatch({ type: SET_SELECTED_INVENTORY })
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setDeletingId()
  }

  const confirmDelete = () => {
    deleteInventory(deletingId)
    handleCloseDelete()
  }

  // update inventory names
  useEffect(() => {
    if (inventory && colors && products && sizes && stores && barcodes) {
      dispatch({
        type: SET_INVENTORY_NAMES,
        payload: inventory
          ?.map(i => {
            return {
              id: i.id,
              barcode: barcodes?.find(
                b =>
                  b.idProduct === i.idProduct &&
                  b.idSize === i.idSize &&
                  b.idColor === i.idColor
              )?.barcode,
              color: colors?.find(c => c.id === i.idColor)?.name,
              product: products?.find(p => p.id === i.idProduct)?.name,
              size: sizes?.find(s => s.id === i.idSize)?.name,
              store: stores?.find(s => s.id === i.idStore)?.name,
              quantity: i.quantity,
              price: i.price,
            }
          })
          ?.filter(
            i =>
              (!selectedColor || i.color === selectedColor) &&
              (!selectedProduct || i.product === selectedProduct) &&
              (!selectedSize || i.size === selectedSize) &&
              (!selectedStore || i.store === selectedStore)
          ),
      })
    }
  }, [
    barcodes,
    colors,
    dispatch,
    inventory,
    products,
    selectedColor,
    selectedProduct,
    selectedSize,
    selectedStore,
    sizes,
    stores,
  ])

  // update counters
  useEffect(() => {
    if (inventoryNames && stores) {
      const tempStores = inventoryNames
        ?.map(s => s.store)
        ?.filter((v, i, a) => a.indexOf(v) === i)

      setQuantityByStore(
        tempStores?.map(s => {
          return {
            store: s,
            quantity: inventoryNames
              ?.filter(i => i.store === s)
              ?.map(i => i.quantity)
              ?.reduce((a, b) => a + b, 0),
          }
        })
      )

      setPriceByStore(
        tempStores
          ?.map(s => {
            return {
              store: s,
              currency: stores?.find(i => i.name === s)?.currency,
              price: inventoryNames
                ?.filter(i => i.store === s)
                ?.map(i => i.quantity * i.price || 0)
                ?.reduce((a, b) => a + b, 0),
            }
          })
          ?.filter(s => s.price > 0)
      )
    }
  }, [inventoryNames, stores])

  // add inventory
  const add = async () => {
    if (
      codiceUtente &&
      newProduct?.id &&
      newStore?.id &&
      typeof newQuantity === 'number'
    ) {
      try {
        await sendRequest(
          `inventory/add`,
          'POST',
          JSON.stringify({
            idColor: newColor?.id,
            idProduct: newProduct.id,
            idSize: newSize?.id,
            idStore: newStore.id,
            quantity: newQuantity,
            price: newPrice,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseAdd()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossible to add inventory: missing data',
      })
    }
  }

  // update inventory
  const update = async () => {
    if (
      codiceUtente &&
      selectedInventory?.id &&
      typeof newQuantity === 'number'
    ) {
      try {
        await sendRequest(
          `inventory/${selectedInventory.id}`,
          'PATCH',
          JSON.stringify({ quantity: newQuantity, price: newPrice }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseUpdate()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossible to update inventory: missing data',
      })
    }
  }

  // transfer inventory
  const transfer = async () => {
    if (
      codiceUtente &&
      selectedInventory?.id &&
      newStore?.id &&
      typeof newQuantity === 'number'
    ) {
      try {
        await sendRequest(
          `inventory/transfer/${selectedInventory.id}`,
          'POST',
          JSON.stringify({
            idStore: newStore.id,
            quantity: newQuantity,
            price: newPrice,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseTransfer()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossible to transfer inventory: missing data',
      })
    }
  }

  // delete inventory
  const deleteInventory = async id => {
    if (codiceUtente && id) {
      try {
        await sendRequest(`inventory/${id}`, 'DELETE', null, {
          Authorization: codiceUtente,
        })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } else {
      dispatch({ type: SET_ERROR, payload: 'Impossible to delete inventory' })
    }
  }

  return (
    <Grid container padding={3}>
      <Grid item xs={12}>
        <center>
          <h2>Inventory</h2>
        </center>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <center>
            <ColouredButton
              textbold='bold'
              textcolour={colours.white}
              backgroundcolour={colours.blue}
              hovercolour={colours.blueDark}
              onClick={handleClickOpenAdd}
            >
              Add item
            </ColouredButton>
          </center>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>
      &nbsp;
      <SelectionBar />
      &nbsp;
      {(!inventoryNames && (
        <Grid item xs={12}>
          <center>
            <h4>Inventory not found.</h4>
          </center>
        </Grid>
      )) ||
        (inventoryNames.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>No items.</h4>
            </center>
          </Grid>
        )) || (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <center>
                  <b>
                    {quantityByStore
                      ?.map(s => s.quantity)
                      ?.reduce((a, b) => a + b, 0)}{' '}
                    total products
                  </b>
                  <br />
                  <br />
                  {quantityByStore
                    ?.map(s => s.quantity)
                    ?.reduce((a, b) => a + b, 0) > 0 &&
                    `${quantityByStore
                      ?.sort((a, b) => b.quantity - a.quantity)
                      ?.map(q => `${q.quantity} in ${q.store}`)
                      ?.join(' + ')}`}
                </center>
              </Grid>
              <Grid item xs={12} lg={6}>
                <center>
                  <b>
                    {priceByStore
                      ?.map(s => s.price)
                      ?.reduce((a, b) => a + b, 0)
                      ?.toFixed(2)}{' '}
                    total price
                  </b>
                  <br />
                  <br />
                  {priceByStore?.map(s => s.price)?.reduce((a, b) => a + b, 0) >
                    0 &&
                    `${priceByStore
                      ?.sort((a, b) => b.price - a.price)
                      ?.map(
                        c => `${c.price?.toFixed(2)}${c.currency} in ${c.store}`
                      )
                      ?.join(' + ')}`}
                </center>
              </Grid>
            </Grid>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={inventoryNames}
                  setTable={t =>
                    dispatch({ type: SET_INVENTORY_NAMES, payload: t })
                  }
                  columns={columns}
                  sortingColumn={inventorySortingColumn}
                  setSortingColumn={SET_INVENTORY_SORTING_COLUMN}
                  sortingAscending={inventorySortingAscending}
                  setSortingAscending={SET_INVENTORY_SORTING_ASCENDING}
                />
                <TableBody>
                  {inventoryNames.map(i => {
                    return (
                      <TableRow
                        key={i.id}
                        sx={{
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                      >
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          <b>{i.barcode ? `#${i.barcode}` : undefined}</b>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {i.product}
                        </TableCell>
                        <TableCell align='center'>
                          <center>
                            {i.size && (
                              <Chip
                                label={
                                  sizes?.find(s => s.name === i.size)?.code
                                }
                                sx={{ bgcolor: colours.greyVeryLight }}
                              />
                            )}
                          </center>
                        </TableCell>
                        <TableCell align='center'>
                          <center>
                            {i.color && (
                              <Chip
                                label={i.color}
                                sx={{
                                  bgcolor: `#${
                                    colors?.find(c => c.name === i.color)
                                      ?.hexCode
                                  }`,
                                }}
                              />
                            )}
                          </center>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {i.store}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                          onClick={() => handleClickOpenUpdate(i)}
                        >
                          {i.quantity || 'Sold out'}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                          onClick={() => handleClickOpenUpdate(i)}
                        >
                          {i.price &&
                            `${i.price?.toFixed(2)} ${
                              stores?.find(s => s.name === i.store)?.currency
                            }`}
                        </TableCell>
                        <TableCell align='center'>
                          {i.quantity > 0 ? (
                            <IconButton
                              style={{ color: colours.blue }}
                              onClick={() => handleClickOpenTransfer(i)}
                            >
                              <Tooltip title='Transfer'>
                                <FlightIcon
                                  sx={{ transform: 'rotate(45deg)' }}
                                />
                              </Tooltip>
                            </IconButton>
                          ) : (
                            <IconButton
                              style={{ color: colours.red }}
                              onClick={() => handleClickOpenDelete(i.id)}
                            >
                              <Tooltip title='Delete'>
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      <DialogMini
        open={openAdd}
        handleClose={handleCloseAdd}
        title={titles.add}
        textUndo='Undo'
        textConfirm='Ok'
        triggerFunction={add}
        addInventory={true}
      />
      <DialogMini
        open={openUpdate}
        handleClose={handleCloseUpdate}
        title={titles.update}
        textUndo='Undo'
        textConfirm='Ok'
        triggerFunction={update}
        updateInventory={true}
      />
      <DialogMini
        open={openTransfer}
        handleClose={handleCloseTransfer}
        title={titles.transfer}
        textUndo='Undo'
        textConfirm='Ok'
        triggerFunction={transfer}
        transferInventory={true}
      />
      <DialogMini
        open={openDelete}
        handleClose={handleCloseDelete}
        title={titles.delete}
        textContent={phrases.delete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={confirmDelete}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  colors: state.home.colors,
  products: state.home.products,
  sizes: state.home.sizes,
  stores: state.home.stores,
  barcodes: state.home.barcodes,
  selectedColor: state.home.selectedColor,
  selectedProduct: state.home.selectedProduct,
  selectedSize: state.home.selectedSize,
  selectedStore: state.home.selectedStore,
  inventory: state.inventory.inventory,
  inventoryNames: state.inventory.inventoryNames,
  inventorySortingColumn: state.inventory.inventorySortingColumn,
  inventorySortingAscending: state.inventory.inventorySortingAscending,
  selectedInventory: state.inventory.selectedInventory,
  newColor: state.inventory.newColor,
  newProduct: state.inventory.newProduct,
  newSize: state.inventory.newSize,
  newStore: state.inventory.newStore,
  newQuantity: state.inventory.newQuantity,
  newPrice: state.inventory.newPrice,
})

const ConnectedInventory = connect(mapStateToProps)(Inventory)

export default ConnectedInventory
