import React from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import { AppBar, Box, Chip, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { colours } from '../settings/settings'
import { useAuth } from '../hooks/auth-hook'
import { SET_IS_OPEN_DRAWER } from '../container/home/types'

const Header = ({ Title = 'GED HOMEMADE', auth, isOpenDrawer }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const login = () => history.push('/login')

  const { logout } = useAuth(auth)

  const toggleDrawer = () =>
    dispatch({ type: SET_IS_OPEN_DRAWER, payload: !isOpenDrawer })

  return (
    <AppBar
      position='fixed'
      open={isOpenDrawer}
      sx={{
        color: colours.blueDark,
        backgroundColor: colours.headerBack,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box sx={{ display: { xs: 'flex' }, flexGrow: 1 }}>
          {auth ? (
            <IconButton
              style={{ color: colours.blueDark }}
              onClick={toggleDrawer}
            >
              <MenuIcon fontSize='large' />
            </IconButton>
          ) : (
            <a href='/'>
              <img src='/logo192.png' alt='Menu' width='45px' />
            </a>
          )}
        </Box>
        <Box sx={{ display: { xs: 'flex' }, flexGrow: 1 }}>
          <center>
            <h3 style={{ margin: 0 }}>{Title}</h3>
          </center>
        </Box>
        <Box sx={{ display: { xs: 'flex' }, flexGrow: 0 }}>
          <center>
            <Chip
              variant='outlined'
              style={{
                fontWeight: 'bold',
                color: colours.headerBack,
                borderColor: colours.headerBack,
                backgroundColor: colours.blueDark,
              }}
              label={auth ? 'Logout' : 'Login'}
              onClick={auth ? logout : login}
            />
          </center>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
  isOpenDrawer: state.home.isOpenDrawer,
})

const ConnectedHeader = connect(mapStateToProps)(Header)

export default ConnectedHeader
