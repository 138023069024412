import React from 'react'
import { Link } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import DialogColors from './DialogColors'
import DialogProducts from './DialogProducts'
import DialogSizes from './DialogSizes'
import DialogStores from './DialogStores'
import DialogAddInventory from './DialogAddInventory'
import DialogUpdateInventory from './DialogUpdateInventory'
import DialogTransferInventory from './DialogTransferInventory'
import DialogAddSale from './DialogAddSale'
import DialogUpdateSale from './DialogUpdateSale'
import { ColouredButton, TransparentButton } from '../components/Buttons'
import { colours } from '../settings/settings'

const DialogMini = ({
  open = false,
  handleClose = () => {},
  title = 'Title',
  textContent,
  textUndo,
  textConfirm,
  triggerFunction = () => {},
  triggerPath,
  colourText = colours.white,
  colourBackground = colours.blue,
  colourHover = colours.blueDark,
  colors = false,
  products = false,
  sizes = false,
  stores = false,
  addInventory = false,
  updateInventory = false,
  transferInventory = false,
  addSale = false,
  updateSale = false,
}) => (
  <Dialog
    open={open}
    onClose={(_, reason) => {
      if (!['backdropClick', 'escapeKeyDown'].includes(reason)) handleClose()
    }}
    fullWidth={true}
    maxWidth={
      colors || products || sizes || stores
        ? 'md'
        : addInventory ||
          updateInventory ||
          transferInventory ||
          addSale ||
          updateSale
        ? 'sm'
        : 'xs'
    }
    transitionDuration={{ enter: 250, exit: 0 }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {textContent && <DialogContentText>{textContent}</DialogContentText>}
      {colors && <DialogColors />}
      {products && <DialogProducts />}
      {sizes && <DialogSizes />}
      {stores && <DialogStores />}
      {addInventory && <DialogAddInventory />}
      {updateInventory && <DialogUpdateInventory />}
      {transferInventory && <DialogTransferInventory />}
      {addSale && <DialogAddSale />}
      {updateSale && <DialogUpdateSale />}
    </DialogContent>
    <DialogActions>
      {textUndo && (
        <TransparentButton onClick={handleClose}>{textUndo}</TransparentButton>
      )}
      &nbsp;
      {textConfirm &&
        ((triggerPath && (
          <Link
            to={triggerPath}
            style={{ textDecoration: 'none' }}
            onClick={triggerFunction}
          >
            <ColouredButton
              textcolour={colourText}
              textbold='bold'
              backgroundcolour={colourBackground}
              hovercolour={colourHover}
            >
              {textConfirm}
            </ColouredButton>
          </Link>
        )) || (
          <ColouredButton
            textcolour={colourText}
            textbold='bold'
            backgroundcolour={colourBackground}
            hovercolour={colourHover}
            onClick={triggerFunction}
          >
            {textConfirm}
          </ColouredButton>
        ))}
    </DialogActions>
  </Dialog>
)

export default DialogMini
