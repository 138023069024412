export const RESET_INVENTORY = '@@inventory/RESET_INVENTORY'

export const SET_INVENTORY = '@@inventory/SET_INVENTORY'
export const SET_INVENTORY_NAMES = '@@inventory/SET_INVENTORY_NAMES'
export const SET_INVENTORY_SORTING_COLUMN =
  '@@inventory/SET_INVENTORY_SORTING_COLUMN'
export const SET_INVENTORY_SORTING_ASCENDING =
  '@@inventory/SET_INVENTORY_SORTING_ASCENDING'

export const SET_SELECTED_INVENTORY = '@@inventory/SET_SELECTED_INVENTORY'
export const SET_NEW_COLOR = '@@inventory/SET_NEW_COLOR'
export const SET_NEW_PRODUCT = '@@inventory/SET_NEW_PRODUCT'
export const SET_NEW_SIZE = '@@inventory/SET_NEW_SIZE'
export const SET_NEW_STORE = '@@inventory/SET_NEW_STORE'
export const SET_NEW_QUANTITY = '@@inventory/SET_NEW_QUANTITY'
export const SET_NEW_PRICE = '@@inventory/SET_NEW_PRICE'
