import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Chip,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import { ColouredButton } from '../components/Buttons'
import { colours, titlesSales as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { utilsFunctions } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_SALES_NAMES,
  SET_SALES_SORTING_COLUMN,
  SET_SALES_SORTING_ASCENDING,
  SET_SELECTED_SALE,
} from '../container/sales/types'

const Sales = ({
  codiceUtente,
  dummyUpdate,
  colors,
  products,
  sizes,
  stores,
  barcodes,
  selectedColor,
  selectedProduct,
  selectedSize,
  selectedStore,
  selectedDates,
  sales,
  salesNames,
  salesSortingColumn,
  salesSortingAscending,
  selectedSale,
  newColor,
  newProduct,
  newSize,
  newStore,
  newQuantity,
  newPrice,
  newNotes,
}) => {
  const dispatch = useDispatch()

  document.title = 'GED - Sales'

  let columns = [
    { label: 'Local Date', sortable: true, sortingField: 'timestampUTC' },
    { label: 'Barcode', sortable: true, sortingField: 'barcode' },
    { label: 'Product', sortable: true, sortingField: 'product' },
    { label: 'Size', sortable: true, sortingField: 'size' },
    { label: 'Color', sortable: true, sortingField: 'color' },
    { label: 'Store', sortable: true, sortingField: 'store' },
    { label: 'Quantity', sortable: true, sortingField: 'quantity' },
    { label: 'Cashed', sortable: true, sortingField: 'price' },
    { label: 'Notes', sortable: true, sortingField: 'notes' },
  ]

  // states to manage the counters
  const [quantityByStore, setQuantityByStore] = useState()
  const [cashedByStore, setCashedByStore] = useState()

  // states to manage the dialogs
  const [openAdd, setOpenAdd] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)

  const { fromTimestampToDateString } = utilsFunctions()

  // memoize function to prevent infinite loop
  const fromTimestampToDateStringM = useCallback(fromTimestampToDateString, [])

  const handleClickOpenAdd = () => setOpenAdd(true)

  const handleClickOpenUpdate = i => {
    dispatch({ type: SET_SELECTED_SALE, payload: i })
    setOpenUpdate(true)
  }

  const handleCloseAdd = () => setOpenAdd(false)

  const handleCloseUpdate = () => {
    setOpenUpdate(false)
    dispatch({ type: SET_SELECTED_SALE })
  }

  // update sales names
  useEffect(() => {
    if (sales && colors && products && sizes && stores && barcodes) {
      const dateFrom = selectedDates?.[0]
      const dateTo = selectedDates?.[1] + 24 * 60 * 60 * 1000

      dispatch({
        type: SET_SALES_NAMES,
        payload: sales
          ?.map(i => {
            return {
              id: i.id,
              barcode: barcodes?.find(
                b =>
                  b.idProduct === i.idProduct &&
                  b.idSize === i.idSize &&
                  b.idColor === i.idColor
              )?.barcode,
              color: colors?.find(c => c.id === i.idColor)?.name,
              product: products?.find(p => p.id === i.idProduct)?.name,
              size: sizes?.find(s => s.id === i.idSize)?.name,
              store: stores?.find(s => s.id === i.idStore)?.name,
              quantity: i.quantity,
              price: i.price,
              notes: i.notes,
              timestampUTC: new Date(i.timestampUTC),
            }
          })
          ?.filter(
            i =>
              (!selectedColor || i.color === selectedColor) &&
              (!selectedProduct || i.product === selectedProduct) &&
              (!selectedSize || i.size === selectedSize) &&
              (!selectedStore || i.store === selectedStore) &&
              (!selectedDates ||
                (i.timestampUTC >= dateFrom && i.timestampUTC <= dateTo))
          ),
      })
    }
  }, [
    barcodes,
    colors,
    dispatch,
    fromTimestampToDateStringM,
    products,
    sales,
    selectedColor,
    selectedDates,
    selectedProduct,
    selectedSize,
    selectedStore,
    sizes,
    stores,
  ])

  // update counters
  useEffect(() => {
    if (salesNames && stores) {
      const tempStores = salesNames
        ?.map(s => s.store)
        ?.filter((v, i, a) => a.indexOf(v) === i)

      setQuantityByStore(
        tempStores?.map(s => {
          return {
            store: s,
            quantity: salesNames
              ?.filter(i => i.store === s)
              ?.map(i => i.quantity)
              ?.reduce((a, b) => a + b, 0),
          }
        })
      )

      setCashedByStore(
        tempStores?.map(s => {
          return {
            store: s,
            currency: stores?.find(i => i.name === s)?.currency,
            cashed: salesNames
              ?.filter(i => i.store === s)
              ?.map(i => i.price)
              ?.reduce((a, b) => a + b, 0),
          }
        })
      )
    }
  }, [salesNames, stores])

  // add sale
  const add = async () => {
    if (
      codiceUtente &&
      newProduct?.id &&
      newStore?.id &&
      typeof newQuantity === 'number' &&
      typeof newPrice === 'number'
    ) {
      try {
        await sendRequest(
          `sales/add`,
          'POST',
          JSON.stringify({
            idColor: newColor?.id,
            idProduct: newProduct.id,
            idSize: newSize?.id,
            idStore: newStore.id,
            quantity: newQuantity,
            price: newPrice,
            notes: newNotes,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseAdd()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossible to add sales: missing data',
      })
    }
  }

  // update sale
  const update = async () => {
    if (codiceUtente && selectedSale?.id && typeof newQuantity === 'number') {
      try {
        await sendRequest(
          `sales/${selectedSale.id}`,
          'PATCH',
          JSON.stringify({
            quantity: newQuantity,
            price: newPrice,
            notes: newNotes,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseUpdate()
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    } else {
      dispatch({
        type: SET_ERROR,
        payload: 'Impossible to update sale: missing data',
      })
    }
  }

  return (
    <Grid container padding={3}>
      <Grid item xs={12}>
        <center>
          <h2>Sales</h2>
        </center>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <center>
            <ColouredButton
              textbold='bold'
              textcolour={colours.white}
              backgroundcolour={colours.blue}
              hovercolour={colours.blueDark}
              onClick={handleClickOpenAdd}
            >
              Add sale
            </ColouredButton>
          </center>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>
      &nbsp;
      <SelectionBar includePeriodo />
      &nbsp;
      {(!salesNames && (
        <Grid item xs={12}>
          <center>
            <h4>Sales not found.</h4>
          </center>
        </Grid>
      )) ||
        (salesNames.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>No sales.</h4>
            </center>
          </Grid>
        )) || (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} lg={6}>
                <center>
                  <b>
                    {quantityByStore
                      ?.map(s => s.quantity)
                      ?.reduce((a, b) => a + b, 0)}{' '}
                    products sold
                  </b>
                  <br />
                  <br />
                  {quantityByStore
                    ?.sort((a, b) => b.quantity - a.quantity)
                    ?.map(q => `${q.quantity} in ${q.store}`)
                    ?.join(' + ')}
                </center>
              </Grid>
              <Grid item xs={12} lg={6}>
                <center>
                  <b>
                    {cashedByStore
                      ?.map(s => s.cashed)
                      ?.reduce((a, b) => a + b, 0)
                      ?.toFixed(2)}{' '}
                    cashed
                  </b>
                  <br />
                  <br />
                  {cashedByStore
                    ?.sort((a, b) => b.cashed - a.cashed)
                    ?.map(
                      c => `${c.cashed?.toFixed(2)}${c.currency} in ${c.store}`
                    )
                    ?.join(' + ')}
                </center>
              </Grid>
            </Grid>
            &nbsp;
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={salesNames}
                  setTable={t =>
                    dispatch({ type: SET_SALES_NAMES, payload: t })
                  }
                  columns={columns}
                  sortingColumn={salesSortingColumn}
                  setSortingColumn={SET_SALES_SORTING_COLUMN}
                  sortingAscending={salesSortingAscending}
                  setSortingAscending={SET_SALES_SORTING_ASCENDING}
                />
                <TableBody>
                  {salesNames.map(i => (
                    <TableRow
                      key={i.id}
                      sx={{
                        ':hover': { backgroundColor: colours.greyVeryLight },
                      }}
                    >
                      <TableCell align='center'>
                        {i.timestampUTC?.toLocaleDateString()}
                        &nbsp;
                        {i.timestampUTC?.toLocaleTimeString(undefined, {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </TableCell>
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        <b>{i.barcode ? `#${i.barcode}` : undefined}</b>
                      </TableCell>
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {i.product}
                      </TableCell>
                      <TableCell align='center'>
                        <center>
                          {i.size && (
                            <Chip
                              label={sizes?.find(s => s.name === i.size)?.code}
                              sx={{ bgcolor: colours.greyVeryLight }}
                            />
                          )}
                        </center>
                      </TableCell>
                      <TableCell align='center'>
                        <center>
                          {i.color && (
                            <Chip
                              label={i.color}
                              sx={{
                                bgcolor: `#${
                                  colors?.find(c => c.name === i.color)?.hexCode
                                }`,
                              }}
                            />
                          )}
                        </center>
                      </TableCell>
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {i.store}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => handleClickOpenUpdate(i)}
                      >
                        {i.quantity}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => handleClickOpenUpdate(i)}
                      >
                        {typeof i.price === 'number'
                          ? i.price === 0
                            ? 'free'
                            : `${i.price?.toFixed(2)} ${
                                stores?.find(s => s.name === i.store)?.currency
                              }`
                          : '?'}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClickOpenUpdate(i)}
                      >
                        {i.notes}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      <DialogMini
        open={openAdd}
        handleClose={handleCloseAdd}
        title={titles.add}
        textUndo='Undo'
        textConfirm='Ok'
        triggerFunction={add}
        addSale={true}
      />
      <DialogMini
        open={openUpdate}
        handleClose={handleCloseUpdate}
        title={titles.update}
        textUndo='Undo'
        textConfirm='Ok'
        triggerFunction={update}
        updateSale={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  colors: state.home.colors,
  products: state.home.products,
  sizes: state.home.sizes,
  stores: state.home.stores,
  barcodes: state.home.barcodes,
  selectedColor: state.home.selectedColor,
  selectedProduct: state.home.selectedProduct,
  selectedSize: state.home.selectedSize,
  selectedStore: state.home.selectedStore,
  selectedDates: state.home.selectedDates,
  sales: state.sales.sales,
  salesNames: state.sales.salesNames,
  salesSortingColumn: state.sales.salesSortingColumn,
  salesSortingAscending: state.sales.salesSortingAscending,
  selectedInventory: state.inventory.selectedInventory,
  selectedSale: state.sales.selectedSale,
  newColor: state.inventory.newColor,
  newProduct: state.inventory.newProduct,
  newSize: state.inventory.newSize,
  newStore: state.inventory.newStore,
  newQuantity: state.sales.newQuantity,
  newPrice: state.sales.newPrice,
  newNotes: state.sales.newNotes,
})

const ConnectedSales = connect(mapStateToProps)(Sales)

export default ConnectedSales
