import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { IconButton, Snackbar, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Header from './components/Header'
import Drawer from './components/Drawer'
import Home from './pages/Home'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Registry from './pages/Registry'
import Inventory from './pages/Inventory'
import Sales from './pages/Sales'
import { useFetch } from './hooks/fetch-hook'
import { SET_ERROR } from './container/home/types'

const App = ({ auth, codiceUtente, error, dummyUpdate }) => {
  const dispatch = useDispatch()

  useFetch(codiceUtente, dummyUpdate)

  const errorHandler = () => {
    dispatch({ type: SET_ERROR, payload: undefined })
  }

  const DynamicHome = () => (auth ? <Dashboard /> : <Home />)

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        onClose={errorHandler}
        message={error}
        action={
          <IconButton size='small' onClick={errorHandler}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />
      <Router>
        <Header />
        <Toolbar />
        <div style={{ display: 'flex' }}>
          {auth && <Drawer />}
          <Switch>
            <Route path='/' exact>
              <DynamicHome />
            </Route>
            <Route path='/login' exact>
              <Login />
            </Route>
            {auth === undefined ? (
              <DynamicHome />
            ) : auth === false ? (
              <Redirect to='/' />
            ) : (
              <Switch>
                <Route path='/registry' exact>
                  <Registry />
                </Route>
                <Route path='/inventory' exact>
                  <Inventory />
                </Route>
                <Route path='/sales' exact>
                  <Sales />
                </Route>
                <Redirect to='/' />
              </Switch>
            )}
            <Redirect to='/' />
          </Switch>
        </div>
        &nbsp;
      </Router>
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
  codiceUtente: state.home.codiceUtente,
  error: state.home.error,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedApp = connect(mapStateToProps)(App)

export default ConnectedApp
