import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import { sendRequest } from '../hooks/http-hook'
import { SET_ERROR, SET_STATS } from '../container/home/types'

const Dashboard = ({ codiceUtente, stats }) => {
  const dispatch = useDispatch()

  document.title = 'GED - Home'

  // fetch stats
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const statsData = await sendRequest('stats', 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!statsData) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find stats' })
        } else {
          dispatch({ type: SET_STATS, payload: statsData.data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) fetchStats()
  }, [codiceUtente, dispatch])

  return (
    <Grid container item xs={12}>
      &nbsp;
      <Grid
        container
        item
        xs={12}
        marginY={2}
        rowSpacing={6}
        alignItems='stretch'
      >
        <GridCard
          title='Registry'
          subtitle1={`${
            stats?.colors * stats?.products * stats?.sizes || 0
          } combinations`}
          linkTo='/registry'
        />
        <GridCard
          title='Inventory'
          subtitle1={`${stats?.inventory || 0} products ready`}
          linkTo='/inventory'
        />
        <GridCard
          title='Sales'
          subtitle1={`${stats?.sales || 0} products sold`}
          linkTo='/sales'
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  stats: state.home.stats,
})

const ConnectedDashboard = connect(mapStateToProps)(Dashboard)

export default ConnectedDashboard
