import React from 'react'
import { useDispatch } from 'react-redux'
import { TableHead, TableRow, TableCell } from '@mui/material'

import { colours } from '../settings/settings'

const SortableTableHead = ({
  table,
  setTable,
  columns,
  sortingColumn,
  setSortingColumn,
  sortingAscending,
  setSortingAscending,
}) => {
  const dispatch = useDispatch()

  return (
    <TableHead style={{ backgroundColor: colours.greyVeryLight }}>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column.label} align='center'>
            <b
              style={{
                color: colours.greyVeryDark,
                cursor: column.sortable && 'pointer',
              }}
              onClick={() => {
                if (column.sortable) {
                  if (
                    !!sortingColumn &&
                    sortingColumn === column.sortingField
                  ) {
                    dispatch({
                      type: setSortingAscending,
                      payload: !sortingAscending,
                    })
                    setTable(
                      table.sort((a, b) =>
                        a[column.sortingField] === undefined
                          ? 1
                          : b[column.sortingField] === undefined
                          ? -1
                          : a[column.sortingField] === b[column.sortingField]
                          ? 0
                          : a[column.sortingField] > b[column.sortingField]
                          ? !sortingAscending
                            ? 1
                            : -1
                          : !sortingAscending
                          ? -1
                          : 1
                      )
                    )
                  } else {
                    dispatch({
                      type: setSortingColumn,
                      payload: column.sortingField,
                    })
                    dispatch({
                      type: setSortingAscending,
                      payload: true,
                    })
                    setTable(
                      table.sort((a, b) =>
                        a[column.sortingField] === undefined
                          ? 1
                          : b[column.sortingField] === undefined
                          ? -1
                          : a[column.sortingField] === b[column.sortingField]
                          ? 0
                          : a[column.sortingField] > b[column.sortingField]
                          ? 1
                          : -1
                      )
                    )
                  }
                }
              }}
            >
              {column.label}
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default SortableTableHead
