import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { sendRequest } from './http-hook'
import {
  SET_ERROR,
  SET_COLORS,
  SET_PRODUCTS,
  SET_SIZES,
  SET_STORES,
  SET_BARCODES,
} from '../container/home/types'
import { SET_INVENTORY } from '../container/inventory/types'
import { SET_SALES } from '../container/sales/types'

export const useFetch = (codiceUtente, dummyUpdate) => {
  const dispatch = useDispatch()

  // fetch colors
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`colors/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find colors' })
        } else {
          dispatch({
            type: SET_COLORS,
            payload: data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_COLORS })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch products
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`products/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find products' })
        } else {
          dispatch({
            type: SET_PRODUCTS,
            payload: data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_PRODUCTS })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch sizes
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`sizes/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find sizes' })
        } else {
          dispatch({
            type: SET_SIZES,
            payload: data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_SIZES })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch stores
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`stores/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find stores' })
        } else {
          dispatch({
            type: SET_STORES,
            payload: data?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_STORES })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch barcodes
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`barcodes/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find barcodes' })
        } else {
          dispatch({
            type: SET_BARCODES,
            payload: data?.sort((a, b) => +a.barcode - +b.barcode),
          })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_BARCODES })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch inventory
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`inventory/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find inventory' })
        } else {
          dispatch({ type: SET_INVENTORY, payload: data })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_INVENTORY })
    }
  }, [codiceUtente, dispatch, dummyUpdate])

  // fetch sales
  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await sendRequest(`sales/all`, 'GET', null, {
          Authorization: codiceUtente,
        })

        if (!data) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to find sales' })
        } else {
          dispatch({ type: SET_SALES, payload: data?.reverse() })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (codiceUtente) {
      fetch()
    } else {
      dispatch({ type: SET_SALES })
    }
  }, [codiceUtente, dispatch, dummyUpdate])
}
