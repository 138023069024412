export const RESET_HOME = '@@home/RESET_HOME'

export const SET_AUTH = '@@home/SET_AUTH'
export const SET_CODICE_UTENTE = '@@home/SET_CODICE_UTENTE'

export const SET_ERROR = '@@home/SET_ERROR'
export const SET_DUMMY_UPDATE = '@@home/SET_DUMMY_UPDATE'
export const SET_IS_MOBILE = '@@home/SET_IS_MOBILE'
export const SET_IS_OPEN_DRAWER = '@@home/SET_IS_OPEN_DRAWER'

export const SET_STATS = '@@home/SET_STATS'

export const SET_COLORS = '@@home/SET_COLORS'
export const SET_COLORS_SORTING_COLUMN = '@@home/SET_COLORS_SORTING_COLUMN'
export const SET_COLORS_SORTING_ASCENDING =
  '@@home/SET_COLORS_SORTING_ASCENDING'

export const SET_PRODUCTS = '@@home/SET_PRODUCTS'
export const SET_PRODUCTS_SORTING_COLUMN = '@@home/SET_PRODUCTS_SORTING_COLUMN'
export const SET_PRODUCTS_SORTING_ASCENDING =
  '@@home/SET_PRODUCTS_SORTING_ASCENDING'

export const SET_SIZES = '@@home/SET_SIZES'
export const SET_SIZES_SORTING_COLUMN = '@@home/SET_SIZES_SORTING_COLUMN'
export const SET_SIZES_SORTING_ASCENDING = '@@home/SET_SIZES_SORTING_ASCENDING'

export const SET_STORES = '@@home/SET_STORES'
export const SET_STORES_SORTING_COLUMN = '@@home/SET_STORES_SORTING_COLUMN'
export const SET_STORES_SORTING_ASCENDING =
  '@@home/SET_STORES_SORTING_ASCENDING'

export const SET_BARCODES = '@@home/SET_BARCODES'

export const SET_SELECTED_COLOR = '@@home/SET_SELECTED_COLOR'
export const SET_SELECTED_PRODUCT = '@@home/SET_SELECTED_PRODUCT'
export const SET_SELECTED_SIZE = '@@home/SET_SELECTED_SIZE'
export const SET_SELECTED_STORE = '@@home/SET_SELECTED_STORE'
export const SET_SELECTED_DATES = '@@home/SET_SELECTED_DATES'
