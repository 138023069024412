import { combineReducers } from 'redux'

import { homeReducer } from '../container/home/reducer'
import { inventoryReducer } from '../container/inventory/reducer'
import { salesReducer } from '../container/sales/reducer'

export const rootReducers = combineReducers({
  home: homeReducer,
  inventory: inventoryReducer,
  sales: salesReducer,
})
