import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'

import InputField from '../components/InputField'
import { ColouredButton } from '../components/Buttons'
import { useAuth } from '../hooks/auth-hook'
import { colours } from '../settings/settings'

const Login = ({ auth }) => {
  const [codiceUtente, setCodiceUtente] = useState()
  const [password, setPassword] = useState()

  const { login } = useAuth(auth)

  document.title = 'GED - Login'

  return (
    <Grid container paddingY={2} alignItems='center' justifyContent='center'>
      <Grid
        container
        item
        xs={12}
        md={8}
        alignItems='center'
        direction='column'
      >
        <form
          style={{ width: '100%' }}
          onSubmit={e => {
            e.preventDefault() // prevents page reload
            login(codiceUtente, password)
          }}
        >
          <Grid item>
            <center>
              <h2>Login</h2>
            </center>
          </Grid>
          <Grid container item alignItems='center'>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <InputField
                id='codiceSocieta'
                value={codiceUtente}
                onChange={setCodiceUtente}
                autoFocus={true}
                label='Username'
                type='text'
              />
            </Grid>
          </Grid>
          <Grid container item alignItems='center'>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <InputField
                id='password'
                value={password}
                onChange={setPassword}
                label='Password'
                type='password'
              />
            </Grid>
          </Grid>
          <Grid item>
            <center>
              <ColouredButton
                type='submit'
                textbold='bold'
                textcolour={colours.white}
                backgroundcolour={colours.blue}
                hovercolour={colours.blueDark}
                disabled={!codiceUtente || !password}
              >
                Go
              </ColouredButton>
            </center>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  auth: state.home.auth,
})

const ConnectedLogin = connect(mapStateToProps)(Login)

export default ConnectedLogin
