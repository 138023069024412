import React from 'react'
import { connect } from 'react-redux'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import { Grid, Tooltip } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EventBusyIcon from '@mui/icons-material/EventBusy'

import Select from './Select'
import { colours } from '../settings/settings'
import { useSelection } from '../hooks/selection-hooks'

const SelectionBar = ({
  includeColor = true,
  includeProduct = true,
  includeSize = true,
  includeStore = true,
  includePeriodo = false,
  isMobile,
  colors,
  products,
  sizes,
  stores,
  selectedColor,
  selectedProduct,
  selectedSize,
  selectedStore,
  selectedDates,
}) => {
  const {
    setSelectedColor,
    setSelectedProduct,
    setSelectedSize,
    setSelectedStore,
    setSelectedDates,
  } = useSelection(
    colors,
    products,
    sizes,
    stores,
    selectedColor,
    selectedProduct,
    selectedSize,
    selectedStore,
    selectedDates
  )

  return (
    <Grid container paddingX={2} spacing={2} alignItems='center'>
      {includeProduct && (
        <Grid item xs={12} md={3}>
          <Select
            id='product'
            value={
              products?.map(p => p.name)?.includes(selectedProduct)
                ? selectedProduct
                : undefined
            }
            options={products?.map(p => p.name)}
            onChange={setSelectedProduct}
            label='Product'
            disabled={!products}
            optional={true}
          />
        </Grid>
      )}
      {includeSize && (
        <Grid item xs={12} md={2}>
          <Select
            id='size'
            value={
              sizes?.map(s => s.name)?.includes(selectedSize)
                ? selectedSize
                : undefined
            }
            options={sizes?.map(s => s.name)}
            onChange={setSelectedSize}
            label='Size'
            disabled={!sizes}
            optional={true}
          />
        </Grid>
      )}
      {includeColor && (
        <Grid item xs={12} md={2}>
          <Select
            id='colors'
            value={
              colors?.map(c => c?.name)?.includes(selectedColor)
                ? selectedColor
                : undefined
            }
            options={colors?.map(c => c?.name)}
            onChange={setSelectedColor}
            label='Color'
            disabled={!colors}
            optional={true}
          />
        </Grid>
      )}
      {includeStore && (
        <Grid item xs={12} md={2}>
          <Select
            id='store'
            value={
              stores?.map(s => s.name)?.includes(selectedStore)
                ? selectedStore
                : undefined
            }
            options={stores?.map(s => s.name)}
            onChange={setSelectedStore}
            label='Store'
            disabled={!stores}
            optional={true}
          />
        </Grid>
      )}
      {includePeriodo && (
        <Grid container item xs={12} md={3} spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <center>
              <small style={{ color: colours.greyDark }}>
                {selectedDates?.length === 2
                  ? `${new Date(
                      selectedDates[0]
                    )?.toLocaleDateString()} - ${new Date(
                      selectedDates[1]
                    )?.toLocaleDateString()}`
                  : 'Choose a period'}
              </small>
            </center>
          </Grid>
          <Grid item xs={3}>
            <center>
              <DatePicker
                id='date-picker'
                value={selectedDates}
                onChange={i =>
                  setSelectedDates(
                    i?.map(ii => new Date(ii)?.setHours(0, 0, 0, 0))
                  )
                }
                disabled={false}
                disableYearPicker={true}
                disableMonthPicker={true}
                fullYear={false}
                hideOnScroll={true}
                highlightToday={false}
                multiple={false}
                range={true}
                readOnly={false}
                sort={true}
                calendarPosition='left'
                format='DD/MM/YYYY'
                numberOfMonths={1}
                weekStartDayIndex={1}
                currentDate={new DateObject()}
                className={isMobile && 'rmdp-mobile'}
                mobileLabels={{ OK: 'OK', CANCEL: 'UNDO' }}
                render={(_, openCalendar) => (
                  <Tooltip title='Select period (UTC)'>
                    <DateRangeIcon
                      color='action'
                      fontSize='small'
                      sx={{ cursor: 'pointer' }}
                      onClick={openCalendar}
                    />
                  </Tooltip>
                )}
              />
            </center>
          </Grid>
          <Grid item xs={3}>
            <center>
              <Tooltip title='Clear period'>
                <EventBusyIcon
                  color='action'
                  fontSize='small'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setSelectedDates()}
                />
              </Tooltip>
            </center>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  isMobile: state.home.isMobile,
  colors: state.home.colors,
  products: state.home.products,
  sizes: state.home.sizes,
  stores: state.home.stores,
  selectedColor: state.home.selectedColor,
  selectedProduct: state.home.selectedProduct,
  selectedSize: state.home.selectedSize,
  selectedStore: state.home.selectedStore,
  selectedDates: state.home.selectedDates,
})

const ConnectedSelectionBar = connect(mapStateToProps)(SelectionBar)

export default ConnectedSelectionBar
