import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'

import { colours } from '../settings/settings'

const GridCard = ({ title, subtitle1, subtitle2, linkTo, isError = false }) => {
  const GridContent = () => {
    return (
      <Grid
        container
        item
        paddingX={3}
        paddingY={2}
        rowSpacing={2}
        sx={{
          width: '100%',
          color: colours.greyVeryDark,
          border: 1,
          borderRadius: 2,
          borderColor: !!isError ? colours.redDark : colours.blueDark,
          background: `linear-gradient(0deg, transparent 25%, ${
            !!isError ? colours.redError : colours.blueBack
          } 100%)`,
        }}
      >
        {!!title && (
          <Grid item xs={12}>
            <b
              style={{
                fontSize: 24,
                color: !!isError ? colours.redDark : colours.blueVeryDark,
              }}
            >
              {title.toUpperCase()}
            </b>
          </Grid>
        )}
        {!!subtitle1 && (
          <Grid item xs={12}>
            {subtitle1}
          </Grid>
        )}
        {!!subtitle2 && (
          <Grid item xs={12}>
            {subtitle2}
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid container item xs={12} md={6} lg={4} paddingX={3}>
      {!!linkTo ? (
        <Link to={linkTo} style={{ textDecoration: 'none', width: '100%' }}>
          {GridContent()}
        </Link>
      ) : (
        GridContent()
      )}
    </Grid>
  )
}

export default GridCard
