import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'

import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import {
  SET_ERROR,
  SET_DUMMY_UPDATE,
  SET_SIZES,
  SET_SIZES_SORTING_COLUMN,
  SET_SIZES_SORTING_ASCENDING,
} from '../container/home/types'

const columns = [
  { label: 'Name', sortable: true, sortingField: 'name' },
  { label: 'Code', sortable: true, sortingField: 'code' },
  { label: 'Notes', sortable: true, sortingField: 'notes' },
  { label: 'Options', sortable: false },
]

const DialogSizes = ({
  codiceUtente,
  dummyUpdate,
  sizes,
  sizesSortingColumn,
  sizesSortingAscending,
}) => {
  const dispatch = useDispatch()

  // states to manage new values
  const [isAdding, setIsAdding] = useState()
  const [idUpdating, setIdUpdating] = useState()
  const [newName, setNewName] = useState()
  const [newCode, setNewCode] = useState()
  const [newNotes, setNewNotes] = useState()

  // reset state
  const resetState = () => {
    setIsAdding()
    setIdUpdating()
    setNewName()
    setNewCode()
    setNewNotes()
  }

  // add
  const addSize = async () => {
    try {
      await sendRequest(
        `sizes/add`,
        'POST',
        JSON.stringify({ name: newName, code: newCode, notes: newNotes }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      dispatch({ type: SET_ERROR, payload: 'Size added correctly' })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // update
  const updateSize = async id => {
    try {
      await sendRequest(
        `sizes/${id}`,
        'PUT',
        JSON.stringify({ name: newName, code: newCode, notes: newNotes }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    resetState()
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  // delete
  const deleteSize = async id => {
    try {
      await sendRequest(`sizes/${id}`, 'DELETE', null, {
        Authorization: codiceUtente,
      })
    } catch (err) {
      console.log(err)
      dispatch({ type: SET_ERROR, payload: err.message })
    }
    dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
  }

  return (
    <>
      <Grid container item xs={12} spacing={3} alignItems='center'>
        {(isAdding && (
          <>
            <Grid item xs={12} md={4}>
              <center>
                <InputField
                  id='name'
                  value={newName}
                  onChange={setNewName}
                  autoFocus={true}
                  label={columns[0].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={12} md={2}>
              <center>
                <InputField
                  id='code'
                  value={newCode}
                  onChange={setNewCode}
                  label={columns[1].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={12} md={4}>
              <center>
                <InputField
                  id='notes'
                  value={newNotes}
                  onChange={setNewNotes}
                  label={columns[2].label}
                  helperText=''
                />
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.blueDark }}
                  onClick={addSize}
                >
                  <SaveIcon />
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton style={{ color: colours.red }} onClick={resetState}>
                  <ClearIcon />
                </IconButton>
              </center>
            </Grid>
          </>
        )) || (
          <Grid item xs={12}>
            <center>
              <IconButton
                style={{ color: colours.black }}
                onClick={() => {
                  setIsAdding(true)
                  setIdUpdating()
                  setNewName()
                  setNewCode()
                  setNewNotes()
                }}
              >
                <AddIcon />
                &nbsp; New size
              </IconButton>
            </center>
          </Grid>
        )}
      </Grid>
      &nbsp;
      {(!sizes && <h4>Sizes not found</h4>) ||
        (sizes.length === 0 && <h4>No sizes found</h4>) || (
          <TableContainer component={Paper}>
            <Table size='small'>
              <SortableTableHead
                table={sizes}
                setTable={i => dispatch({ type: SET_SIZES, payload: i })}
                columns={columns}
                sortingColumn={sizesSortingColumn}
                sortingAscending={sizesSortingAscending}
                setSortingColumn={SET_SIZES_SORTING_COLUMN}
                setSortingAscending={SET_SIZES_SORTING_ASCENDING}
              />
              <TableBody>
                {sizes.map(p => {
                  return (
                    <TableRow
                      key={p.id}
                      style={{ backgroundSize: colours.white }}
                    >
                      {(idUpdating === p.id && (
                        <>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='name'
                              value={newName}
                              onChange={setNewName}
                              autoFocus={true}
                              label={columns[0].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='code'
                              value={newCode}
                              onChange={setNewCode}
                              label={columns[1].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <InputField
                              id='notes'
                              value={newNotes}
                              onChange={setNewNotes}
                              label={columns[2].label}
                              helperText=''
                              mode='update'
                            />
                          </TableCell>
                          <TableCell align='center' padding='checkbox'>
                            <Grid container>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => updateSize(p.id)}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={resetState}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )) || (
                        <>
                          <TableCell align='center'>{p.name}</TableCell>
                          <TableCell align='center'>{p.code}</TableCell>
                          <TableCell align='center'>{p.notes}</TableCell>
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.blueDark }}
                                  onClick={() => {
                                    setIsAdding()
                                    setIdUpdating(p.id)
                                    setNewName(p.name)
                                    setNewCode(p.code)
                                    setNewNotes(p.notes)
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={() => deleteSize(p.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  codiceUtente: state.home.codiceUtente,
  dummyUpdate: state.home.dummyUpdate,
  sizes: state.home.sizes,
  sizesSortingColumn: state.home.sizesSortingColumn,
  sizesSortingAscending: state.home.sizesSortingAscending,
})

const ConnectedDialogSizes = connect(mapStateToProps)(DialogSizes)

export default ConnectedDialogSizes
