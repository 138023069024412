import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Chip, Grid } from '@mui/material'

import Select from './Select'
import InputField from './InputField'
import { colours } from '../settings/settings'
import { errorFunctions } from '../hooks/error-functions'
import {
  SET_NEW_STORE,
  SET_NEW_QUANTITY,
  SET_NEW_PRICE,
} from '../container/inventory/types'

const DialogTransferInventory = ({
  colors,
  sizes,
  stores,
  selectedInventory,
  newStore,
  newQuantity,
  newPrice,
}) => {
  const dispatch = useDispatch()

  const mode = 'update'

  // error functions
  const { errorNaturale, errorPositivo } = errorFunctions()

  // set inputs
  useEffect(() => {
    dispatch({
      type: SET_NEW_STORE,
      payload: stores?.filter(s => s.name !== selectedInventory?.store)?.[0],
    })
    dispatch({ type: SET_NEW_QUANTITY, payload: 1 })
    dispatch({ type: SET_NEW_PRICE, payload: selectedInventory?.price })
  }, [dispatch, selectedInventory, stores])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_STORE })
      dispatch({ type: SET_NEW_QUANTITY })
      dispatch({ type: SET_NEW_PRICE })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      {(!selectedInventory && (
        <Grid item xs={12}>
          No item selected.
        </Grid>
      )) || (
        <Grid container rowGap={2} alignItems='center'>
          <Grid container>
            <Grid item xs sx={{ whiteSpace: 'nowrap' }}>
              <center>
                <b>{selectedInventory?.product}</b>
              </center>
            </Grid>
            {selectedInventory?.size && (
              <Grid item xs>
                <center>
                  <Chip
                    label={
                      sizes?.find(x => x.name === selectedInventory?.size)?.code
                    }
                    sx={{ bgcolor: colours.greyVeryLight }}
                  />
                </center>
              </Grid>
            )}
            {selectedInventory?.color && (
              <Grid item xs>
                <center>
                  <Chip
                    label={selectedInventory?.color}
                    sx={{
                      bgcolor: `#${
                        colors?.find(c => c.name === selectedInventory?.color)
                          ?.hexCode
                      }`,
                    }}
                  />
                </center>
              </Grid>
            )}
          </Grid>
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              Old store&nbsp;<b>{selectedInventory?.store}</b>
            </Grid>
            <Grid item xs={6}>
              <Select
                id='store'
                label='New store'
                value={newStore}
                options={stores?.filter(
                  s => s.name !== selectedInventory?.store
                )}
                optionLabels={stores
                  ?.filter(s => s.name !== selectedInventory?.store)
                  ?.map(s => s?.name)}
                onChange={i => dispatch({ type: SET_NEW_STORE, payload: i })}
                errorText='Select a store'
                mode={mode}
              />
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              Quantity left&nbsp;
              <b>{selectedInventory?.quantity - (newQuantity || 0)}</b>
            </Grid>
            <Grid item xs={6}>
              <center>
                <InputField
                  id='quantity'
                  value={newQuantity}
                  onChange={i =>
                    dispatch({ type: SET_NEW_QUANTITY, payload: i })
                  }
                  label={`Quantity (max ${selectedInventory?.quantity})`}
                  errorFunc={x =>
                    x === 0 ||
                    errorNaturale(x) ||
                    x > selectedInventory?.quantity
                  }
                  type='number'
                  mode={mode}
                />
              </center>
            </Grid>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item xs={6}>
              Price&nbsp;<b>{selectedInventory?.price}</b>
            </Grid>
            <Grid item xs={6}>
              <center>
                <InputField
                  id='price'
                  value={newPrice}
                  onChange={i => dispatch({ type: SET_NEW_PRICE, payload: i })}
                  label='New Price'
                  errorText='Cannot be negative'
                  errorFunc={i => i && errorPositivo(i)}
                  type='number'
                  mode={mode}
                />
              </center>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  colors: state.home.colors,
  sizes: state.home.sizes,
  stores: state.home.stores,
  selectedInventory: state.inventory.selectedInventory,
  newStore: state.inventory.newStore,
  newQuantity: state.inventory.newQuantity,
  newPrice: state.inventory.newPrice,
})

const ConnectedDialogTransferInventory = connect(mapStateToProps)(
  DialogTransferInventory
)

export default ConnectedDialogTransferInventory
