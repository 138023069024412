import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Chip, Grid } from '@mui/material'

import Select from './Select'
import InputField from './InputField'
import AutoComplete from './AutoComplete'
import { errorFunctions } from '../hooks/error-functions'
import { colours } from '../settings/settings'
import {
  SET_NEW_COLOR,
  SET_NEW_PRODUCT,
  SET_NEW_SIZE,
  SET_NEW_STORE,
  SET_NEW_QUANTITY,
  SET_NEW_PRICE,
  SET_NEW_NOTES,
} from '../container/sales/types'

const DialogAddSale = ({
  colors,
  products,
  sizes,
  stores,
  barcodes,
  inventory,
  newColor,
  newProduct,
  newSize,
  newStore,
  newQuantity,
  newPrice,
  newNotes,
}) => {
  const dispatch = useDispatch()

  const mode = 'add'

  const sum = arr => arr?.reduce((a, b) => a + b, 0)

  // available items
  const [availableItems, setAvailableItems] = useState()
  const [availableColors, setAvailableColors] = useState()
  const [availableProducts, setAvailableProducts] = useState()
  const [availableSizes, setAvailableSizes] = useState()
  const [availableStores, setAvailableStores] = useState()
  const [availableQuantity, setAvailableQuantity] = useState()
  const [availableBarcodes, setAvailableBarcodes] = useState()
  const [selectedBarcode, setSelectedBarcode] = useState()

  // error functions
  const { errorNaturale, errorPositivo } = errorFunctions()

  // reset newStoreSales
  const resetNewStore = useCallback(async () => {
    localStorage.removeItem('newStoreSales')
    dispatch({ type: SET_NEW_STORE })
  }, [dispatch])

  // set newStoreSales
  const setNewStore = useCallback(
    async x => {
      if (x?.name) {
        localStorage.setItem('newStoreSales', JSON.stringify(x?.name))
        dispatch({ type: SET_NEW_STORE, payload: x })
      } else {
        resetNewStore()
      }
    },
    [dispatch, resetNewStore]
  )

  // check newStoreSales
  useEffect(() => {
    if (!newStore && stores?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('newStoreSales'))
        if (stores?.map(s => s.name).includes(storedValue)) {
          dispatch({
            type: SET_NEW_STORE,
            payload: stores?.find(s => s.name === storedValue),
          })
        } else {
          resetNewStore()
        }
      } catch {
        resetNewStore()
      }

    if (
      newStore &&
      stores?.length > 0 &&
      !stores?.map(s => s.name)?.includes(newStore?.name)
    )
      resetNewStore()
  }, [dispatch, newStore, resetNewStore, stores])

  // set inputs
  useEffect(() => {
    dispatch({ type: SET_NEW_COLOR })
    dispatch({ type: SET_NEW_PRODUCT })
    dispatch({ type: SET_NEW_SIZE })
    dispatch({ type: SET_NEW_QUANTITY })
    dispatch({ type: SET_NEW_PRICE })
    dispatch({ type: SET_NEW_NOTES })
  }, [dispatch])

  // update available items
  useEffect(() => {
    setAvailableItems(
      inventory
        ?.filter(i => i.quantity > 0)
        ?.filter(
          i =>
            (!selectedBarcode ||
              (i.idProduct === selectedBarcode.idProduct &&
                i.idSize === selectedBarcode.idSize &&
                i.idColor === selectedBarcode.idColor)) &&
            (!newColor || i.idColor === newColor.id) &&
            (!newProduct || i.idProduct === newProduct.id) &&
            (!newSize || i.idSize === newSize.id) &&
            (!newStore || i.idStore === newStore.id)
        )
    )
  }, [inventory, newColor, newProduct, newSize, newStore, selectedBarcode])

  // update available barcodes
  useEffect(() => {
    setAvailableBarcodes(
      barcodes?.filter(b =>
        availableItems?.some(
          a =>
            a.idProduct === b.idProduct &&
            a.idSize === b.idSize &&
            a.idColor === b.idColor
        )
      )
    )
  }, [availableItems, barcodes])

  // update available colors
  useEffect(() => {
    setAvailableColors(
      colors?.filter(c => availableItems?.map(a => a.idColor)?.includes(c.id))
    )
  }, [availableItems, colors])

  // update available products
  useEffect(() => {
    setAvailableProducts(
      products?.filter(p =>
        availableItems?.map(a => a.idProduct)?.includes(p.id)
      )
    )
  }, [availableItems, products])

  // update available sizes
  useEffect(() => {
    setAvailableSizes(
      sizes?.filter(s => availableItems?.map(a => a.idSize)?.includes(s.id))
    )
  }, [availableItems, sizes])

  // update available stores
  useEffect(() => {
    setAvailableStores(
      newProduct
        ? stores?.filter(s =>
            availableItems?.map(a => a.idStore)?.includes(s.id)
          )
        : stores
    )
  }, [availableItems, newProduct, stores])

  // update available stores
  useEffect(() => {
    setAvailableQuantity(
      newProduct ? sum(availableItems?.map(a => a.quantity)) || 0 : undefined
    )
  }, [availableItems, newProduct])

  // autoselect barcode
  useEffect(() => {
    if (availableBarcodes?.length === 1)
      setSelectedBarcode(availableBarcodes[0])
  }, [availableBarcodes])

  // autoselect color
  useEffect(() => {
    if (availableColors?.length === 1 && availableItems?.every(a => a.idColor))
      dispatch({ type: SET_NEW_COLOR, payload: availableColors[0] })
  }, [availableColors, availableItems, dispatch])

  // autoselect product
  useEffect(() => {
    if (
      availableProducts?.length === 1 &&
      availableItems?.every(a => a.idProduct)
    )
      dispatch({ type: SET_NEW_PRODUCT, payload: availableProducts[0] })
  }, [availableItems, availableProducts, dispatch])

  // autoselect size
  useEffect(() => {
    if (availableSizes?.length === 1 && availableItems?.every(a => a.idSize))
      dispatch({ type: SET_NEW_SIZE, payload: availableSizes[0] })
  }, [availableItems, availableSizes, dispatch])

  // autoselect store
  useEffect(() => {
    if (availableStores?.length === 1 && availableItems?.every(a => a.idStore))
      dispatch({ type: SET_NEW_STORE, payload: availableStores[0] })
  }, [availableItems, availableStores, dispatch])

  // autocomplete quantity
  useEffect(() => {
    if (availableItems?.length === 1)
      dispatch({ type: SET_NEW_QUANTITY, payload: 1 })
  }, [availableItems, dispatch])

  // autocomplete price
  useEffect(() => {
    if (
      availableItems?.length === 1 &&
      availableItems[0]?.price &&
      typeof newQuantity === 'number'
    )
      dispatch({
        type: SET_NEW_PRICE,
        payload: Math.round(availableItems[0].price * newQuantity * 100) / 100,
      })
  }, [availableItems, dispatch, newQuantity])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_NEW_COLOR })
      dispatch({ type: SET_NEW_PRODUCT })
      dispatch({ type: SET_NEW_SIZE })
      dispatch({ type: SET_NEW_STORE })
      dispatch({ type: SET_NEW_QUANTITY })
      dispatch({ type: SET_NEW_PRICE })
      dispatch({ type: SET_NEW_NOTES })
    }
  }, [dispatch])

  return (
    <>
      &nbsp;
      <Grid container spacing={3} alignItems='center'>
        {availableStores?.length && (
          <Grid item xs={12}>
            <Select
              id='store'
              label='Store'
              value={newStore}
              options={availableStores}
              optionLabels={availableStores?.map(s => s?.name)}
              onChange={setNewStore}
              errorText='Select a store'
              mode={mode}
            />
          </Grid>
        )}
        {availableBarcodes?.length && (
          <Grid item xs={12}>
            <AutoComplete
              id='barcode'
              label='Barcode'
              value={selectedBarcode}
              options={availableBarcodes}
              getOptionLabel={op => op?.barcode}
              onChange={setSelectedBarcode}
              errorText='Type a barcode'
              mode={mode}
              type='tel'
            />
          </Grid>
        )}
        {availableProducts?.length && (
          <Grid item xs={12}>
            <Select
              id='product'
              label='Product'
              value={newProduct}
              options={availableProducts}
              optionLabels={availableProducts?.map(p => p?.name)}
              onChange={i => dispatch({ type: SET_NEW_PRODUCT, payload: i })}
              errorText='Select a product'
              mode={mode}
            />
          </Grid>
        )}
        {newSize ? (
          <Grid item xs={6}>
            <center>
              <Chip
                label={sizes?.find(s => s.name === newSize?.name)?.code}
                sx={{ bgcolor: colours.greyVeryLight }}
              />
            </center>
          </Grid>
        ) : availableSizes?.length ? (
          <Grid item xs={12}>
            <Select
              id='size'
              label='Size'
              value={newSize}
              options={availableSizes}
              optionLabels={availableSizes?.map(s => s?.name)}
              onChange={i => dispatch({ type: SET_NEW_SIZE, payload: i })}
              optional={true}
              mode={mode}
            />
          </Grid>
        ) : (
          <Grid item xs={6} />
        )}
        {newColor ? (
          <Grid item xs={6}>
            <center>
              <Chip
                label={newColor.name}
                sx={{
                  bgcolor: `#${
                    colors?.find(c => c.name === newColor.name)?.hexCode
                  }`,
                }}
              />
            </center>
          </Grid>
        ) : availableColors?.length ? (
          <Grid item xs={12}>
            <Select
              id='color'
              label='Color'
              value={newColor}
              options={availableColors}
              optionLabels={availableColors?.map(c => c?.name)}
              onChange={i => dispatch({ type: SET_NEW_COLOR, payload: i })}
              optional={true}
              mode={mode}
            />
          </Grid>
        ) : (
          <Grid item xs={6} />
        )}
        <Grid item xs={12} md={6}>
          <InputField
            id='quantity'
            label={`Quantity${
              availableQuantity ? ` (max ${availableQuantity})` : ''
            }`}
            value={newQuantity}
            onChange={i => dispatch({ type: SET_NEW_QUANTITY, payload: i })}
            errorFunc={x =>
              x === 0 || errorNaturale(x) || x > availableQuantity
            }
            type='number'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='price'
            label='Cashed'
            value={newPrice}
            onChange={i =>
              dispatch({
                type: SET_NEW_PRICE,
                payload: Math.round(i * 100) / 100,
              })
            }
            errorFunc={i => i && errorPositivo(i)}
            errorText='Cannot be negative'
            type='number'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            id='notes'
            label='Notes'
            value={newNotes}
            onChange={i => dispatch({ type: SET_NEW_NOTES, payload: i })}
            helperText=''
            mode={mode}
          />
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  colors: state.home.colors,
  products: state.home.products,
  sizes: state.home.sizes,
  stores: state.home.stores,
  barcodes: state.home.barcodes,
  inventory: state.inventory.inventory,
  newColor: state.sales.newColor,
  newProduct: state.sales.newProduct,
  newSize: state.sales.newSize,
  newStore: state.sales.newStore,
  newQuantity: state.sales.newQuantity,
  newPrice: state.sales.newPrice,
  newNotes: state.sales.newNotes,
})

const ConnectedDialogAddSale = connect(mapStateToProps)(DialogAddSale)

export default ConnectedDialogAddSale
