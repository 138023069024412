import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { sendRequest } from './http-hook'
import {
  RESET_HOME,
  SET_AUTH,
  SET_CODICE_UTENTE,
  SET_ERROR,
} from '../container/home/types'

export const useAuth = auth => {
  const dispatch = useDispatch()

  const history = useHistory()

  const login = useCallback(
    async (codice, password) => {
      try {
        const authData = await sendRequest(
          'login',
          'POST',
          JSON.stringify({ codice, password }),
          { 'Content-Type': 'application/json' }
        )

        if (!authData) {
          dispatch({ type: SET_ERROR, payload: 'Impossible to authenticate' })
        } else {
          localStorage.setItem('codice', JSON.stringify(codice))
          localStorage.setItem('password', JSON.stringify(password))
          dispatch({ type: SET_AUTH, payload: true })
          dispatch({
            type: SET_CODICE_UTENTE,
            payload: authData.data.codiceUtente,
          })
          history.push('/')
        }
      } catch (err) {
        console.log(err)
        localStorage.removeItem('codice')
        localStorage.removeItem('password')
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    },
    [dispatch, history]
  )

  const logout = useCallback(() => {
    localStorage.removeItem('codice')
    localStorage.removeItem('password')

    dispatch({ type: RESET_HOME })

    history.push('/')
  }, [dispatch, history])

  // auto-login
  useEffect(() => {
    if (!auth) {
      try {
        const localCodice = JSON.parse(localStorage.getItem('codice'))
        const localPassword = JSON.parse(localStorage.getItem('password'))
        if (localCodice && localPassword) login(localCodice, localPassword)
      } catch {
        localStorage.removeItem('codice')
        localStorage.removeItem('password')
      }
    }
  }, [auth, login])

  return { login, logout }
}
