import React, { useState } from 'react'
import { Grid } from '@mui/material'

import DialogMini from '../components/DialogMini'
import { TransparentButton } from '../components/Buttons'
import { colours, titlesRegistry } from '../settings/settings'

const Registry = () => {
  document.title = 'GED - Registry'

  const [openColors, setOpenColors] = useState(false)
  const [openProducts, setOpenProducts] = useState(false)
  const [openSizes, setOpenSizes] = useState(false)
  const [openStores, setOpenStores] = useState(false)

  const handleClickOpenColors = () => setOpenColors(true)
  const handleClickOpenProducts = () => setOpenProducts(true)
  const handleClickOpenSizes = () => setOpenSizes(true)
  const handleClickOpenStores = () => setOpenStores(true)

  const handleCloseColors = () => setOpenColors(false)
  const handleCloseProducts = () => setOpenProducts(false)
  const handleCloseSizes = () => setOpenSizes(false)
  const handleCloseStores = () => setOpenStores(false)

  const GridTransparentButton = ({ title, onClick }) => (
    <Grid item xs={12} lg={3}>
      <center>
        <TransparentButton
          borderwidth={1}
          bordercolour={colours.black}
          onClick={onClick}
        >
          {title}
        </TransparentButton>
      </center>
    </Grid>
  )

  return (
    <Grid container padding={3}>
      <Grid item xs={12}>
        <center>
          <h2>Registry</h2>
        </center>
      </Grid>
      &nbsp;
      <Grid container spacing={3}>
        <GridTransparentButton
          title={titlesRegistry.colors}
          onClick={handleClickOpenColors}
        />
        <GridTransparentButton
          title={titlesRegistry.products}
          onClick={handleClickOpenProducts}
        />
        <GridTransparentButton
          title={titlesRegistry.sizes}
          onClick={handleClickOpenSizes}
        />
        <GridTransparentButton
          title={titlesRegistry.stores}
          onClick={handleClickOpenStores}
        />
      </Grid>
      &nbsp;
      <DialogMini
        open={openColors}
        handleClose={handleCloseColors}
        title={titlesRegistry.colors}
        textConfirm='Close'
        triggerFunction={handleCloseColors}
        colors={true}
      />
      <DialogMini
        open={openProducts}
        handleClose={handleCloseProducts}
        title={titlesRegistry.products}
        textConfirm='Close'
        triggerFunction={handleCloseProducts}
        products={true}
      />
      <DialogMini
        open={openSizes}
        handleClose={handleCloseSizes}
        title={titlesRegistry.sizes}
        textConfirm='Close'
        triggerFunction={handleCloseSizes}
        sizes={true}
      />
      <DialogMini
        open={openStores}
        handleClose={handleCloseStores}
        title={titlesRegistry.stores}
        textConfirm='Close'
        triggerFunction={handleCloseStores}
        stores={true}
      />
    </Grid>
  )
}

export default Registry
