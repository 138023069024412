import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  SET_SELECTED_COLOR,
  SET_SELECTED_PRODUCT,
  SET_SELECTED_SIZE,
  SET_SELECTED_STORE,
  SET_SELECTED_DATES,
} from '../container/home/types'

export const useSelection = (
  colors,
  products,
  sizes,
  stores,
  selectedColor,
  selectedProduct,
  selectedSize,
  selectedStore,
  selectedDates
) => {
  const dispatch = useDispatch()

  const resetSelectedColor = useCallback(async () => {
    localStorage.removeItem('selectedColor')
    dispatch({ type: SET_SELECTED_COLOR })
  }, [dispatch])

  const resetSelectedProduct = useCallback(async () => {
    localStorage.removeItem('selectedProduct')
    dispatch({ type: SET_SELECTED_PRODUCT })
  }, [dispatch])

  const resetSelectedSize = useCallback(async () => {
    localStorage.removeItem('selectedSize')
    dispatch({ type: SET_SELECTED_SIZE })
  }, [dispatch])

  const resetSelectedStore = useCallback(async () => {
    localStorage.removeItem('selectedStore')
    dispatch({ type: SET_SELECTED_STORE })
  }, [dispatch])

  const resetSelectedDates = useCallback(async () => {
    localStorage.removeItem('selectedDates')
    dispatch({ type: SET_SELECTED_DATES })
  }, [dispatch])

  const setSelectedColor = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedColor', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_COLOR, payload: x })
      } else {
        resetSelectedColor()
      }
    },
    [dispatch, resetSelectedColor]
  )

  const setSelectedProduct = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedProduct', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_PRODUCT, payload: x })
      } else {
        resetSelectedProduct()
      }
    },
    [dispatch, resetSelectedProduct]
  )

  const setSelectedSize = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedSize', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_SIZE, payload: x })
      } else {
        resetSelectedSize()
      }
    },
    [dispatch, resetSelectedSize]
  )

  const setSelectedStore = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedStore', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_STORE, payload: x })
      } else {
        resetSelectedStore()
      }
    },
    [dispatch, resetSelectedStore]
  )

  const setSelectedDates = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedDates', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_DATES, payload: x })
      } else {
        resetSelectedDates()
      }
    },
    [dispatch, resetSelectedDates]
  )

  // check selectedColor
  useEffect(() => {
    if (!selectedColor && colors?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedColor'))
        if (colors?.map(c => c.name).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_COLOR, payload: storedValue })
        } else {
          resetSelectedColor()
        }
      } catch {
        resetSelectedColor()
      }

    if (
      selectedColor &&
      colors?.length > 0 &&
      !colors?.map(c => c.name)?.includes(selectedColor)
    )
      resetSelectedColor()
  }, [colors, dispatch, resetSelectedColor, selectedColor])

  // check selectedProduct
  useEffect(() => {
    if (!selectedProduct && products?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedProduct'))
        if (products?.map(p => p.name).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_PRODUCT, payload: storedValue })
        } else {
          resetSelectedProduct()
        }
      } catch {
        resetSelectedProduct()
      }

    if (
      selectedProduct &&
      products?.length > 0 &&
      !products?.map(p => p.name)?.includes(selectedProduct)
    )
      resetSelectedProduct()
  }, [dispatch, products, resetSelectedProduct, selectedProduct])

  // check selectedSize
  useEffect(() => {
    if (!selectedSize && sizes?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedSize'))
        if (sizes?.map(s => s.name).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_SIZE, payload: storedValue })
        } else {
          resetSelectedSize()
        }
      } catch {
        resetSelectedSize()
      }

    if (
      selectedSize &&
      sizes?.length > 0 &&
      !sizes?.map(s => s.name)?.includes(selectedSize)
    )
      resetSelectedSize()
  }, [dispatch, resetSelectedSize, selectedSize, sizes])

  // check selectedStore
  useEffect(() => {
    if (!selectedStore && stores?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedStore'))
        if (stores?.map(s => s.name).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_STORE, payload: storedValue })
        } else {
          resetSelectedStore()
        }
      } catch {
        resetSelectedStore()
      }

    if (
      selectedStore &&
      stores?.length > 0 &&
      !stores?.map(s => s.name)?.includes(selectedStore)
    )
      resetSelectedStore()
  }, [dispatch, resetSelectedStore, selectedStore, stores])

  // check selectedDates
  useEffect(() => {
    if (!selectedDates)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedDates'))
        dispatch({ type: SET_SELECTED_DATES, payload: storedValue })
      } catch {
        resetSelectedDates()
      }
  }, [dispatch, resetSelectedDates, selectedDates])

  return {
    setSelectedColor,
    setSelectedProduct,
    setSelectedSize,
    setSelectedStore,
    setSelectedDates,
    resetSelectedColor,
    resetSelectedProduct,
    resetSelectedSize,
    resetSelectedStore,
    resetSelectedDates,
  }
}
